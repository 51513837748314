import React from 'react';

export default function ManageWrap(props) {
	const { children, backColor, style, header, h2Header } = props || {};
	return (
		<main className={`pageInnerCont${backColor ? ` ${backColor}` : ''}`} style={style || null}>
			{header && (
				<div className='mainHeader'>
					<h1 className='headerText'>
						{header.icon}
						{header.text}
					</h1>
				</div>
			)}
			{h2Header && (
				<div className='mainHeader'>
					<h2 className='headerText'>
						{h2Header.icon}
						{h2Header.text}
					</h2>
				</div>
			)}
			{children}
		</main>
	);
}
