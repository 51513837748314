import React, { useContext } from 'react';
import TextInput from '../Inputs/TextInput';
import * as yup from 'yup';
import { useForms } from '../../hooks/useForms';
import config from '../../config';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { splitUserId } from '../../helpers';
import Button from '../Button';
import { FloppyIcon } from '../SVGIcons/SVGIcons';

export default function ChangePassForm() {
	const { userState } = useContext(CurrentUserContext);

	const formValidationSchema = yup.object().shape({
		oldPassword: yup.string().required('{{oldPassword}}{{{Old password is required}}}'),
		newPassword: yup
			.string()
			.required('{{newPassword}}{{{New password is required}}}')
			.min(
				6,
				'{{newPassword}}{{{Incorrect password format. Please enter a password containing ' +
					'between 6 to 20 characters, at least one numeric digit, one ' +
					'uppercase and one lowercase letter}}}'
			)
			.max(
				20,
				'{{newPassword}}{{{Incorrect password format. Please enter a password containing ' +
					'between 6 to 20 characters, at least one numeric digit, one ' +
					'uppercase and one lowercase letter}}}'
			)
			.test(
				'not-same-as-old',
				'{{newPassword}}{{{New password must be different from the old password}}}',
				function (value) {
					return value !== oldPassword.value;
				}
			),
		confirmPassword: yup
			.string()
			.required('{{confirmPassword}}{{{Please confirm your new password}}}')
			.oneOf([yup.ref('newPassword'), null], '{{confirmPassword}}{{{Passwords must match}}}'),
	});

	let changePasswordMap = (formData) => {
		return {
			email: userState?.currUser?.email,
			oldPassword: formData.oldPassword,
			newPassword: formData.newPassword,
		};
	};

	const { formData, updateForm, submitForm, reqLoading, customErrors } = useForms(
		'changePassword',
		formValidationSchema,
		changePasswordMap,
		`${config.auth}/v1/user/password.update`,
		'PATCH'
	);

	const handleFormInput = (value, name) => {
		updateForm({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let success = await submitForm(null, `Password successfully updated`, (e) => {});
	};
	return (
		<form>
			<TextInput
				type='password'
				placeholder='Old password...'
				text='Old password'
				name='oldPassword'
				callback={handleFormInput}
				value={formData?.oldPassword}
				customError={customErrors.oldPassword}
				controlled
			/>
			<TextInput
				type='password'
				text='New password'
				placeholder='New password...'
				name='newPassword'
				callback={handleFormInput}
				value={formData?.newPassword}
				customError={customErrors.newPassword}
				controlled
			/>
			<TextInput
				type='password'
				text='Confirm new password'
				placeholder='Confirm new password...'
				name='confirmPassword'
				callback={handleFormInput}
				value={formData?.confirmPassword}
				customError={customErrors.confirmPassword}
				controlled
			/>
			<div className='btnCont'>
				<Button
					text='Save new password'
					iconLeft={<FloppyIcon />}
					style='primary'
					size='md'
					onClick={(e) => {
						handleSubmit(e);
					}}
				/>
			</div>
		</form>
	);
}
