import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import FadeOutModal from '../../../components/Modals/FadeOutModal';
import FlexModal from '../../../components/Modals/FlexModal';
import Axios from 'axios';
import config from '../../../config';
import { v4 as uuidv4 } from 'uuid';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import { parseToOptions, toBase64 } from '../../../helpers';
import StagingCol from './StagingCol';
import ToolsCol from './ToolsCol';

import Chart1 from '../../../assets/SVG_teeth-charts/FDI-ISO-3950.svg';
import Chart2 from '../../../assets/SVG_teeth-charts/Palmer_notation.svg';
import Chart3 from '../../../assets/SVG_teeth-charts/UNS.svg';
import Chart4 from '../../../assets/SVG_teeth-charts/Primary-teeth_Palmer-notation.svg';
import Library from './Library';
import useModals from '../../../hooks/useModals';
import ColorPicker from './ColorPicker';
import useWindowSize from '../../../hooks/useWindowSize';
import { Navigate } from 'react-router';
import ProcessingModal from '../../../components/Modals/ProcessingModal';
import { Link } from 'react-router-dom';
import Overlay from '../../../components/Overlay';
import SelectInput from '../../../components/Inputs/SelectInput';
import FieldSuggestions from './FieldSuggestions';

const buildFields = [
	{
		id: 'field_1',
		name: 'Dropdown Select',
		paragraph:
			'Use this block when you want the user to pick 1 out of 4 or more options. For less than 4 options use the Radio Group. Please set a default option (pre-selected).',
		type: 'select',
	},
	{ id: 'field_2', name: 'Single-line text', type: 'input.text' },
	{ id: 'field_3', name: 'Phone number', type: 'input.phone' },
	{ id: 'field_4', name: 'Multi-line text', type: 'textarea' },
	{ id: 'field_5', name: 'Date', type: 'input.date' },
	{ id: 'field_6', name: 'File upload', type: 'input.file' },
	{ id: 'field_7', name: 'Single checkbox', type: 'input.checkbox' },
	{ id: 'field_8', name: 'Radio button', type: 'input.radio' },
	{ id: 'field_9', name: 'Header text', type: 'header' },
	{ id: 'field_10', name: 'Paragraph', type: 'paragraph' },

	{ id: 'field_12', name: 'Drawing tool', type: 'drawingTool' },
	{ id: 'field_13', name: 'Graphic', type: 'graphic' },
];

const widgets = [
	{
		id: 'widget_1',
		name: 'FDI-ISO-3950',
		type: 'drawingTool',
		data: {
			fieldName: 'fdiiso3950',
			fieldText: 'FDI-ISO-3950',
			drawToolImg: { name: 'FDI-ISO-3950', data: Chart1 },
		},
	},
	{
		id: 'widget_2',
		name: 'Palmer Notation',
		type: 'drawingTool',
		data: {
			fieldName: 'palmernotation',
			fieldText: 'Palmer Notation',
			drawToolImg: { name: 'Palmer Notation', data: Chart2 },
		},
	},
	{
		id: 'widget_3',
		name: 'UNS',
		type: 'drawingTool',
		data: { fieldName: 'uns', fieldText: 'UNS', drawToolImg: { name: 'UNS', data: Chart3 } },
	},
	{
		id: 'widget_6',
		name: 'Primary Palmer Notation',
		type: 'drawingTool',
		data: {
			fieldName: 'primarypalmernotation',
			fieldText: 'Primary Palmer Notation',
			drawToolImg: { name: 'Primary Palmer Notation', data: Chart4 },
		},
	},
	{
		id: 'widget_4',
		name: 'MHRA Compliance statement',
		type: 'paragraph',
		data: {
			paragraph:
				"This is a custom made medical device that has been manufactured to satisfy the design characteristics and properties specified by the prescriber for the named patient. This medical device is intended for exclusive use by this patient and conforms to the relevant essential requirements specified in Annex I of the Medical Devices Directive and the United Kingdom Medical devices Regulations. This statement does not apply to medical devices that have been repaired and/or refurbished for an individual patient's use.	THIS IS NOT A STERILE DEVICE",
		},
	},
];

const externalTemps = [
	{
		fields: [
			{
				id: 'field_9_c41837b1-fce3-466f-bfa2-31b9ad993bbe',
				name: 'Header text',
				type: 'header',
				data: {
					header: 'Crown & Bridge',
				},
			},
			{
				id: 'field_1_d4858a83-14a2-44b7-9ada-f964508b465f',
				name: 'Single-line text',
				type: 'select',
				data: {
					fieldText: 'Patient Title',
					required: true,
					selectOptions: [
						{
							name: '038afd64-1c9d-4c7a-99a0-96708fb4bb31_patient_title_1',
							value: 'Mr',
							text: 'Option #1',
						},
						{
							name: 'fa016404-f299-4c70-88b8-81829458855b_patient_title_2',
							value: 'Mrs',
							text: 'Option #2',
						},
						{
							name: '1ba800e8-47d3-4425-8ba8-7d361154df52_patient_title_3',
							value: 'Miss',
							text: 'Option #3',
						},
						{
							name: '2e499c9c-8235-4c9f-bd41-fb2d93ff0a06_patient_title_4',
							value: 'Ms',
							text: 'Option #4',
						},
						{
							name: 'ca3b9473-defa-42ef-9a27-fc07d4850dda_patient_title_5',
							value: 'Sir',
							text: 'Option #5',
						},
						{
							name: '4b98c0cd-2f7b-4b5d-ab02-66452beb0a15_patient_title_6',
							value: 'Dame',
							text: 'Option #6',
						},
						{
							name: '048506ba-8d64-477e-a04b-48d5f3ce4d57_patient_title_7',
							value: 'Dr',
							text: 'Option #7',
						},
						{
							name: '2a44fdc9-8628-4830-a2c6-0cf7626751c3_patient_title_8',
							value: 'Cllr',
							text: 'Option #8',
						},
						{
							name: 'bffd522a-0573-4270-8763-6c3342f4f2e0_patient_title_9',
							value: 'Lady',
							text: 'Option #9',
						},
						{
							name: 'd87ecb57-fad9-459a-a6e3-73f444975891_patient_title_10',
							value: 'Lord',
							text: 'Option #10',
						},
					],
					fieldName: 'patienttitle',
				},
			},
			{
				id: 'field_2_h5558a83-14a2-44b7-9ada-f964508b465f',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldName: 'patientfirstname',
					fieldText: 'Patient First Name',
					placeholder: '',
					required: true,
				},
			},
			{
				id: 'field_2_l2258a83-14a2-44b7-9ada-f964508b465f',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldText: 'Patient Surname',
					required: true,
					fieldName: 'patientsurname',
				},
			},
			{
				id: 'field_5_ec377c7e-528b-4a1b-9d69-69abf2e5c111',
				name: 'Date',
				type: 'input.date',
				data: {
					fieldText: 'Date required',
					minDays: '5',
					fieldName: 'daterequired',
					requiredBy: true,
				},
			},
			{
				id: 'field_2_9144baee-2d3c-4905-9e31-6ba376c83b21',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldText: 'Vita Shade',
					fieldName: 'vitashade',
				},
			},
			{
				id: 'field_2_4ec19882-cfe6-4e6f-9867-f6cb701a16a4',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldText: 'Stump Shade',
					fieldName: 'stumpshade',
				},
			},
			{
				id: 'widget_1_d4607c7e-87ca-49bc-b31d-6aef1152ff1b',
				name: 'FDI-ISO-3950',
				type: 'drawingTool',
				data: {
					fieldName: 'FDI-ISO-3950',
					drawToolImg: {
						name: 'FDI-ISO-3950',
						data: Chart1,
					},
					fieldText: 'FDI-ISO-3950',
				},
			},
			{
				id: 'field_4_ee8f9c95-6ff7-4353-8af9-02144075f7f5',
				name: 'Multi-line text',
				type: 'textarea',
				data: {
					fieldText: 'Instructions',
					fieldName: 'instructions',
				},
			},
			{
				id: 'widget_4_573ebba3-5a06-498b-9416-7248d95f271c',
				name: 'MHRA Compliance statement',
				type: 'paragraph',
				data: {
					paragraph:
						"This is a custom made medical device that has been manufactured to satisfy the design characteristics and properties specified by the prescriber for the named patient. This medical device is intended for exclusive use by this patient and conforms to the relevant essential requirements specified in Annex I of the Medical Devices Directive and the United Kingdom Medical devices Regulations. This statement does not apply to medical devices that have been repaired and/or refurbished for an individual patient's use.\tTHIS IS NOT A STERILE DEVICE",
				},
			},
		],
		name: 'Crown & Bridge',
		docketType: 1,
		description: 'Crown & Bridge example. Transactor Systems Ltd Template.',
		color: 'yellow',
	},
	{
		fields: [
			{
				id: 'field_9_4ec6a384-b6da-4409-a4fd-ec615749d566',
				name: 'Header text',
				type: 'header',
				data: {
					header: 'LabManager Licence change Request',
				},
			},
			{
				id: 'field_2_38dfcb83-e084-488b-94f8-f94e6916f818',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldName: 'licensenumber',
					fieldText: 'License Number',
				},
			},
			{
				id: 'field_4_61f581d0-1cf7-4f1b-ba79-cb61e0f73687',
				name: 'Multi-line text',
				type: 'textarea',
				data: {
					fieldName: 'describechangetolicense',
					fieldText: 'Describe change to license',
				},
			},
		],
		name: 'Change Req Test',
		docketType: 2,
	},
	{
		fields: [
			{
				id: 'field_9_1ad7a3f5-8241-45f5-932f-d499833143db',
				name: 'Header text',
				type: 'header',
				data: {
					header: 'Prosthetics',
				},
			},
			{
				id: 'field_1_d4828a53-14a2-44b7-9ada-f964508b467f',
				name: 'Single-line text',
				type: 'select',
				data: {
					fieldText: 'Patient Title',
					required: true,
					selectOptions: [
						{
							name: '038afd64-1cf5-4c7a-99a0-96708fb4bb31_patient_title_1',
							value: 'Mr',
							text: 'Option #1',
						},
						{
							name: 'fa016404-f299-4s70-88b8-81829458855b_patient_title_2',
							value: 'Mrs',
							text: 'Option #2',
						},
						{
							name: '1ba800e8-47d3-4h25-8ba8-7d361154df52_patient_title_3',
							value: 'MIss',
							text: 'Option #3',
						},
						{
							name: '2e499c9c-8235-4v9f-bd41-fb2d93ff0a06_patient_title_4',
							value: 'Ms',
							text: 'Option #4',
						},
						{
							name: 'ca3b9473-defa-42ef-9a67-fc07d4850dda_patient_title_5',
							value: 'Sir',
							text: 'Option #5',
						},
						{
							name: '4b98c0cd-2f7b-4b5d-ab92-66452beb0a15_patient_title_6',
							value: 'Dame',
							text: 'Option #6',
						},
						{
							name: '048506ba-8d64-477e-a00b-48d5f3ce4d57_patient_title_7',
							value: 'Dr',
							text: 'Option #7',
						},
						{
							name: '2a44fdc9-8628-4830-a2j6-0cf7626751c3_patient_title_8',
							value: 'Cllr',
							text: 'Option #8',
						},
						{
							name: 'bffd522a-0573-4270-8743-6c3342f4f2e0_patient_title_9',
							value: 'Lady',
							text: 'Option #9',
						},
						{
							name: 'd87ecb57-fad9-459a-a6q3-73f444975891_patient_title_10',
							value: 'Lord',
							text: 'Option #10',
						},
					],
					fieldName: 'patienttitle',
				},
			},
			{
				id: 'field_2_h5558a8d-14a2-44b7-9ada-f964508b465f',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldName: 'patientfirstname',
					fieldText: 'Patient First Name',
					placeholder: '',
					required: true,
				},
			},
			{
				id: 'field_2_l2258a83-14a2-44b7-9ada-f964508b464t',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldText: 'Patient Surname',
					required: true,
					fieldName: 'patientsurname',
				},
			},
			{
				id: 'field_5_bd0a774a-c07d-4e3d-91d3-045a8fef608a',
				name: 'Date',
				type: 'input.date',
				data: {
					fieldText: 'Try in Date',
					minDays: '5',
					fieldName: 'tryindate',
					requiredBy: true,
				},
			},
			{
				id: 'widget_1_7f847af3-8907-42eb-b394-1197153f7d11',
				name: 'FDI-ISO-3950',
				type: 'drawingTool',
				data: {
					fieldName: 'FDI-ISO-3950',
					fieldText: 'FDI-ISO-3950',
					drawToolImg: {
						name: 'FDI-ISO-3950',
						data: Chart1,
					},
				},
			},
			{
				id: 'field_2_6a48e2a5-07db-470c-b192-3ba510eaef20',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldText: 'Vita Shade',
					fieldName: 'vitashade',
				},
			},
			{
				id: 'field_2_04f96956-4ba9-45f3-8ffa-118e9c297a49',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldText: 'Implant System',
					fieldName: 'implantsystem',
				},
			},
			{
				id: 'field_4_590a8a7e-49ad-4168-95bb-53016d27ea58',
				name: 'Multi-line text',
				type: 'textarea',
				data: {
					fieldText: 'Instructions',
					fieldName: 'instructions',
				},
			},
			{
				id: 'widget_4_9f4c7ccb-ed4e-4861-9e4c-3d897c89b7e9',
				name: 'MHRA Compliance statement',
				type: 'paragraph',
				data: {
					paragraph:
						"This is a custom made medical device that has been manufactured to satisfy the design characteristics and properties specified by the prescriber for the named patient. This medical device is intended for exclusive use by this patient and conforms to the relevant essential requirements specified in Annex I of the Medical Devices Directive and the United Kingdom Medical devices Regulations. This statement does not apply to medical devices that have been repaired and/or refurbished for an individual patient's use.\tTHIS IS NOT A STERILE DEVICE",
				},
			},
		],
		name: 'Prosthetics',
		docketType: 1,
		description: 'Prosthetics example. Transactor Systems Ltd Template.',
		color: 'yellow',
	},
	{
		fields: [
			{
				id: 'field_9_a5b77587-a5a0-4783-813c-15eee727092f',
				name: 'Header text',
				type: 'header',
				data: {
					header: 'Lab Sheet',
				},
			},
			{
				id: 'field_1_d4828a53-14a2-44b7-9ake-f964508b467f',
				name: 'Single-line text',
				type: 'select',
				data: {
					fieldText: 'Patient Title',
					required: true,
					selectOptions: [
						{
							name: '038afd64-1cf5-4c7a-99a0-96708fb7hb31_patient_title_1',
							value: 'Mr',
							text: 'Option #1',
						},
						{
							name: 'fa016404-f299-4s70-88b8-83829458855b_patient_title_2',
							value: 'Mrs',
							text: 'Option #2',
						},
						{
							name: '1ba800e8-47d3-4h25-8ba8-7s361154df52_patient_title_3',
							value: 'MIss',
							text: 'Option #3',
						},
						{
							name: '2e499c9c-8235-4v9f-bd41-fg2d93ff0a06_patient_title_4',
							value: 'Ms',
							text: 'Option #4',
						},
						{
							name: 'ca3b9473-defa-42ef-9a67-fc07d5850dda_patient_title_5',
							value: 'Sir',
							text: 'Option #5',
						},
						{
							name: '4b98c0cd-2f7b-4b5d-ab92-66452keb0a15_patient_title_6',
							value: 'Dame',
							text: 'Option #6',
						},
						{
							name: '048506ba-8d64-477e-a00b-48d5f9ce4d57_patient_title_7',
							value: 'Dr',
							text: 'Option #7',
						},
						{
							name: '2a44fdc9-8628-4830-a2j6-0cf7646731c3_patient_title_8',
							value: 'Cllr',
							text: 'Option #8',
						},
						{
							name: 'bffd522a-0573-4270-8743-6c3342f2d2e0_patient_title_9',
							value: 'Lady',
							text: 'Option #9',
						},
						{
							name: 'd87ecb57-fad9-459a-a6q3-73f444925597_patient_title_10',
							value: 'Lord',
							text: 'Option #10',
						},
					],
					fieldName: 'patienttitle',
				},
			},
			{
				id: 'field_2_h5558a8d-14d2-24b7-9ada-f964508b465g',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldName: 'patientfirstname',
					fieldText: 'Patient First Name',
					placeholder: '',
					required: true,
				},
			},
			{
				id: 'field_2_l2228a53-14a2-44b7-9ada-fw64508b46dt',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldText: 'Patient Surname',
					required: true,
					fieldName: 'patientsurname',
				},
			},
			{
				id: 'widget_1_52f1dbc9-93d0-4a00-ae3b-99d4a3eac25f',
				name: 'FDI-ISO-3950',
				type: 'drawingTool',
				data: {
					fieldName: 'FDI-ISO-3950',
					fieldText: 'FDI-ISO-3950',
					drawToolImg: {
						name: 'FDI-ISO-3950',
						data: Chart1,
					},
				},
			},
			{
				id: 'field_2_1a5a2fa5-2fd3-4e2c-9b1f-ba4346dec6cb',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldText: 'Shade',
					fieldName: 'shade',
				},
			},
			{
				id: 'field_4_f46fb590-98f8-42b5-bb9d-5d425d21200f',
				name: 'Multi-line text',
				type: 'textarea',
				data: {
					fieldText: 'Further instructions',
					fieldName: 'furtherinstructions',
				},
			},
			{
				id: 'field_5_940bb8ed-4b94-48ca-b4b9-21d5454ee930',
				name: 'Date',
				type: 'input.date',
				data: {
					fieldText: 'Return date',
					minDays: '5',
					fieldName: 'returndate',
					requiredBy: true,
				},
			},
		],
		name: 'Basic Lab Sheet',
		docketType: 1,
		description: 'Simple Lab Sheet example. Transactor Systems Ltd Template.',
		color: 'yellow',
	},
];

export default function DocketBuilder() {
	const [formData, setFormData] = useState({});
	const [docketData, setDocketData] = useState({});
	const [modalsOpen, setModalsOpen] = useState({});
	const [isEditing, setIsEditing] = useState(false);
	const [changeMade, setChangeMade] = useState(false);
	const [editingFields, setEditingFields] = useState({});
	const [dropsDisabled, setDropsDisabled] = useState(false);
	const [disablePlaceholder, setDisablePlaceholder] = useState(false);
	const [showDropZones, setShowDropZones] = useState(true);
	const [customErrors, setCustomErrors] = useState({});
	const [selected, setSelected] = useState(1);
	const [dockets, setDockets] = useState([]);
	const [chosenDocket, setChosenDocket] = useState({});

	const [color, setColor] = useState(null);
	const [parentUuid, setParentUuid] = useState(null);
	const [dropdownOpen, setDropdownOpen] = useState(null);

	const [fields, setFields] = useState({
		field_1: {
			id: 'field_1',
			name: 'Dropdown Select',
			paragraph:
				'Use this block when you want the user to pick 1 out of 4 or more options. For less than 4 options use the Radio Group. Please set a default option (pre-selected).',
			type: 'select',
		},
		field_2: { id: 'field_2', name: 'Single-line text', type: 'input.text' },
		field_3: { id: 'field_3', name: 'Phone number', type: 'input.phone' },
		field_4: { id: 'field_4', name: 'Multi-line text', type: 'textarea' },
		field_5: { id: 'field_5', name: 'Date', type: 'input.date' },
		field_6: { id: 'field_6', name: 'File upload', type: 'input.file' },
		field_7: { id: 'field_7', name: 'Single checkbox', type: 'input.checkbox' },
		field_8: { id: 'field_8', name: 'Radio button', type: 'input.radio' },
		field_9: { id: 'field_9', name: 'Header', type: 'header' },
		field_10: { id: 'field_10', name: 'Paragraph', type: 'paragraph' },

		field_12: { id: 'field_12', name: 'Drawing tool', type: 'drawingTool' },
		field_13: { id: 'field_13', name: 'Graphic', type: 'graphic' },
	});
	const [stagingCol, setStagingCol] = useState({
		id: 'column_2',
		type: 'docket',
		title: 'Docket',
		fields: [],
		immutable: false,
	});
	const [toolCol, setToolCol] = useState({
		id: 'column_1',
		type: 'library',
		title: 'Library',
		fields: [],
		immutable: true,
	});

	let colors = ['yellow', 'green', 'purple', 'blue', 'red'];
	let allowedTypes = ['input.text', 'select', 'textarea'];

	const { modalsState, handleModal, handleFadeModal } = useModals();

	const windowSize = useWindowSize();

	const { teamState } = useContext(CurrentTeamContext);

	const onDragStart = (result) => {
		const { destination, source, draggableId } = result;
		if (draggableId === 'container' || draggableId.split('_')[0] === 'container') {
			setDropsDisabled(true);
		}
		if (draggableId.split('_')[0] === 'template') {
			setDisablePlaceholder(true);
		}
		setShowDropZones(true);
	};

	const onDragEnd = (result) => {
		const { destination, source, draggableId } = result;

		const currCols = Array.from([stagingCol, toolCol]);

		setDropsDisabled(false);
		setShowDropZones(false);
		setChangeMade(true);

		if (
			selected === 1 &&
			destination &&
			source.droppableId !== 'column_2' &&
			draggableId.split('_')[0] === 'template'
		) {
			setDisablePlaceholder(false);
			const colStartIndex = currCols.map((column) => column.id).indexOf(source.droppableId);
			const columnStart = currCols[colStartIndex];
			const startFields = Array.from(columnStart.fields);
			delete startFields[source.index].preview;
			return handleChosenDocket(draggableId);
		}

		if (!destination) {
			if (fields[draggableId]) {
				const colStartIndex = currCols.map((column) => column.id).indexOf(source.droppableId);
				const columnStart = currCols[colStartIndex];
				const startFields = Array.from(columnStart.fields);
				delete startFields[source.index].preview;
				delete startFields[source.index].class;
				const newStart = {
					...columnStart,
					fields: startFields,
				};
				currCols[colStartIndex] = newStart;
				setStagingCol(currCols[0]);
				setToolCol(currCols[1]);
			}
			return;
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const toType = destination.droppableId.split('_')[0];
		const fromType = source.droppableId.split('_')[0];

		if (toType === 'container') {
			if (draggableId.split('_')[0] === 'container') {
				return;
			}
			if (draggableId === 'container') {
				return;
			}
		}

		let colStartIndex;
		let colFinishIndex;

		let columnStart;
		let columnFinish;

		if (toType === 'column' && fromType === 'column') {
			colStartIndex = currCols.map((column) => column.id).indexOf(source.droppableId);
			colFinishIndex = currCols.map((column) => column.id).indexOf(destination.droppableId);

			columnStart = currCols[colStartIndex];
			columnFinish = currCols[colFinishIndex];
		} else if (toType === 'container' && fromType === 'column') {
			colStartIndex = currCols.map((column) => column.id).indexOf(source.droppableId);
			colFinishIndex = currCols[0].fields.map((field) => field.droppable?.id).indexOf(destination.droppableId);

			columnStart = currCols[colStartIndex];
			columnFinish = currCols[0].fields[colFinishIndex].droppable;
		} else if (toType === 'column' && fromType === 'container') {
			colStartIndex = currCols[0].fields.map((field) => field.droppable?.id).indexOf(source.droppableId);
			colFinishIndex = currCols.map((column) => column.id).indexOf(destination.droppableId);

			columnStart = currCols[0].fields[colStartIndex].droppable;
			columnFinish = currCols[colFinishIndex];
		} else if (toType === 'container' && fromType === 'container') {
			colStartIndex = currCols[0].fields.map((field) => field.droppable?.id).indexOf(source.droppableId);
			colFinishIndex = currCols[0].fields.map((field) => field.droppable?.id).indexOf(destination.droppableId);

			columnStart = currCols[0].fields[colStartIndex].droppable;
			columnFinish = currCols[0].fields[colFinishIndex].droppable;
		}

		const dragItemIndex = columnStart.fields.map((field) => field.id).indexOf(draggableId);

		if (columnStart.id === columnFinish.id) {
			if (toType === 'column') {
				const newFields = Array.from(columnStart.fields);
				let newDragItem = Object.assign({}, newFields[dragItemIndex]);
				newFields.splice(source.index, 1);
				newFields.splice(destination.index, 0, newDragItem);

				const newColumn = {
					...columnStart,
					fields: newFields,
				};
				currCols[colStartIndex] = newColumn;
			} else if (toType === 'container') {
				const newFields = Array.from(columnStart.fields);
				let newDragItem = Object.assign({}, newFields[dragItemIndex]);
				newFields.splice(source.index, 1);
				newFields.splice(destination.index, 0, newDragItem);

				const newColumn = {
					...columnStart,
					fields: newFields,
				};
				currCols[0].fields[colFinishIndex].droppable = newColumn;
			}
			setStagingCol(currCols[0]);
			setToolCol(currCols[1]);
			return;
		}

		const startFields = Array.from(columnStart.fields);
		if (!columnStart.immutable) {
			startFields.splice(source.index, 1);
		}

		if (fields[draggableId]) {
			delete columnStart.fields[source.index].preview;
			delete columnStart.fields[source.index].class;
		}

		const newStart = {
			...columnStart,
			fields: startFields,
		};

		const finishFields = Array.from(columnFinish.fields);
		let newDragItem = Object.assign({}, columnStart.fields[dragItemIndex]);
		newDragItem.class = fields[draggableId]?.class;

		if (!newDragItem.id.split('_')[2]) {
			newDragItem.id = `${newDragItem.id}_${uuidv4()}`;
		}
		if (newDragItem.droppable && !newDragItem.droppable?.id?.split('_')[2]) {
			newDragItem.droppable.id = `${newDragItem.droppable?.id}_${uuidv4()}`;
		}

		if (!columnFinish.immutable) {
			finishFields.splice(destination.index, 0, newDragItem);
		}

		if (draggableId.split('_')[0] === 'widget') {
			let dupeWidgets = columnFinish.fields?.filter(
				(field) =>
					field?.id?.split('_')[1] === draggableId.split('_')[1] &&
					field?.id?.split('_')[0] === draggableId.split('_')[0]
			);
			if (dupeWidgets?.length > 0) {
				newDragItem.data.fieldName = `${columnStart.fields[dragItemIndex]?.data?.fieldName?.split('_')[0]}_${
					dupeWidgets?.length + 1
				}`;
				newDragItem.data.fieldText = `${columnStart.fields[dragItemIndex]?.data?.fieldText?.split('_')[0]}_${
					dupeWidgets?.length + 1
				}`;
			}
		}

		const newFinish = {
			...columnFinish,
			fields: finishFields,
		};

		if (fromType === 'container') {
			currCols[0].fields[colStartIndex].droppable = newStart;
		}
		if (toType === 'container') {
			currCols[0].fields[colFinishIndex].droppable = newFinish;
		}
		if (fromType === 'column') {
			currCols[colStartIndex] = newStart;
		}
		if (toType === 'column') {
			currCols[colFinishIndex] = newFinish;
		}

		setStagingCol(currCols[0]);
		setToolCol(currCols[1]);
	};

	const onBeforeCapture = (result) => {
		const { draggableId } = result;
		// if (fields[draggableId]) {
		// 	const dragItemIndex = columns[1].fields.map((field) => field.id).indexOf(draggableId);
		// 	const cols = Array.from(columns);
		// 	cols[1].fields[dragItemIndex].preview = true;
		// 	setColumns([...cols]);
		// }
	};

	const onDragUpdate = (result) => {
		const { draggableId, destination } = result;
	};

	function handleFadeOutModal(state) {
		const second = 1000;
		setModalsOpen((modalsOpen) => ({
			...modalsOpen,
			['modalFading']: state,
		}));
		setTimeout(() => {
			setModalsOpen((modalsOpen) => ({
				...modalsOpen,
				['modalFading']: { show: true, isFading: true, message: state.message },
			}));
		}, second * state.duration || second * 3);
	}

	function handleDocketInput(value, name) {
		setCustomErrors({});
		setDocketData((docketData) => ({
			...docketData,
			[name]: value,
		}));
	}

	const handleDropdown = (docketUuid) => {
		if (docketUuid === dropdownOpen) {
			setDropdownOpen(null);
		} else {
			setDropdownOpen(docketUuid);
		}
	};

	async function handleFile(value, name) {
		setCustomErrors({});
		let base64Img = await toBase64(value);

		setFormData((formData) => ({
			...formData,
			[name]: { name: value.name, data: base64Img },
		}));
	}

	function handleFormInput(value, name, noSpace) {
		setCustomErrors({});
		if (noSpace && value.includes(' ') && name !== 'fieldId') {
			return;
		}
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	}

	function addRadio() {
		let length = 0;
		let name = `radio3`;
		let radios = formData.extraRadios || {};
		if (typeof formData.extraRadios === 'object') {
			length = Object.keys(formData.extraRadios).length;
			name = `radio${length + 3}`;
			radios[name] = { value: name, text: name, inputText: `Radio choice #${length + 3}` };
		} else {
			radios[name] = { value: name, text: name, inputText: `Radio choice #3` };
		}
		setFormData((formData) => ({
			...formData,
			extraRadios: radios,
		}));
	}

	function saveFieldData() {
		function checkInvalidFields(elements) {
			for (let i = 0; i < elements.length; i++) {
				const isOption = formData?.selectOptions?.filter(
					(option) => option.name === elements[i].getAttribute('isrequired')
				);
				if (isOption?.length > 0) {
					if (isOption[0].value === null) {
						setCustomErrors({
							...customErrors,
							[elements[i].getAttribute('isrequired')]: 'Please fill in the required field',
						});
						elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
						return true;
					}
				} else {
					if (!formData[elements[i].getAttribute('isrequired')]) {
						setCustomErrors({
							...customErrors,
							[elements[i].getAttribute('isrequired')]: 'Please fill in the required field',
						});
						elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
						return true;
					}
				}
			}
			return false;
		}

		function checkIfDupeName() {
			let fieldName = formData.fieldName || formData.fieldText?.replace(' ', '')?.toLowerCase();
			for (let i = 0; i < stagingCol.fields.length; i++) {
				if (stagingCol.fields[i].data && stagingCol.fields[i].id !== editingFields?.field.id) {
					if (
						fieldName &&
						stagingCol.fields[i].data?.fieldName &&
						fieldName?.toLowerCase() === stagingCol.fields[i].data?.fieldName?.toLowerCase()
					) {
						setCustomErrors({
							...customErrors,
							['fieldText']: 'This label is already present in the form. Please choose another label',
						});
						return true;
					}
				}
			}
			return false;
		}

		const elements = document.querySelectorAll('[isrequired]');
		let invalid = checkInvalidFields(elements) || checkIfDupeName();

		if (invalid) {
			return;
		}

		setChangeMade(true);

		let type = editingFields.colId.split('_')[0];

		let fieldIndex;
		let stagingField;
		let newCol = Object.assign(stagingCol, {});

		if (type === 'column') {
			insertNewColFieldData(stagingCol, editingFields, formData);
		} else if (type === 'container') {
			let contIndex = stagingCol.fields.map((field) => field.id).indexOf(editingFields?.data?.colId);
			fieldIndex = stagingCol.fields[contIndex].droppable.fields
				.map((field) => field.id)
				.indexOf(editingFields?.data?.field?.id);
			stagingField = stagingCol.fields[contIndex];
			stagingField.droppable.fields[fieldIndex].data = formData || editingFields?.data?.field?.data;
			if (stagingField.droppable.fields[fieldIndex].data.fieldText) {
				stagingField.droppable.fields[fieldIndex].data.fieldName = stagingField.data.fieldText;
				stagingField.droppable.fields[fieldIndex].data.fieldName.replace(' ', '').toLowerCase();
			}
			newCol.fields[contIndex] = stagingField;
			setStagingCol(newCol);
		}

		setEditingFields(null);
		setFormData({});
	}

	function insertNewColFieldData(stagingCol, editingFields, formData) {
		let fieldIndex;
		let stagingField;
		let newCol = Object.assign(stagingCol, {});

		fieldIndex = stagingCol.fields.map((field) => field.id).indexOf(editingFields?.field?.id);
		stagingField = stagingCol.fields[fieldIndex];
		stagingField.data = formData || editingFields?.field?.data;
		stagingField.data.fieldName = stagingField.data.fieldText
			?.replace(/[^A-Za-z_$\d]|^\d*|[\s.]/g, '')
			.toLowerCase();
		newCol.fields[fieldIndex] = stagingField;

		setStagingCol(newCol);
	}

	function popOption() {
		const options = Array.from(formData?.selectOptions);
		options.pop();
		setFormData({
			...formData,
			selectOptions: options,
		});
	}

	function handleSelectField(value, name) {
		setCustomErrors({});
		const optionIndex = formData?.selectOptions?.map((option) => option.name).indexOf(name);
		const options = Array.from(formData?.selectOptions);
		options[optionIndex].value = value;
		setFormData({
			...formData,
			selectOptions: options,
		});
	}

	function addSelectField(field) {
		setFormData({
			...formData,
			selectOptions: [...(formData?.selectOptions || []), field],
		});
	}

	function removeField(data) {
		const { fieldId, colId } = data || {};
		setChangeMade(true);
		const currCols = Array.from([stagingCol, toolCol]);
		const col = currCols[0];

		const fieldIndex = col?.fields?.map((field) => field.id).indexOf(fieldId);

		const newFields = Array.from(col.fields);
		newFields.splice(fieldIndex, 1);
		const newColumn = {
			...col,
			fields: newFields,
		};
		currCols[0] = newColumn;
		setStagingCol(currCols[0]);
		handleModal('deleteModal', false);
		handleFadeOutModal({
			show: true,
			isFading: false,
			message: 'Field removed',
		});
	}

	function removeFieldPrompt(fieldId, colId) {
		handleModal('deleteModal', {
			value: { fieldId: fieldId, colId: colId },
			callback: removeField,
			header: 'Are you sure you want to remove this field?',
			open: true,
		});
	}

	async function postDocket(data) {
		function checkMissingData() {
			let missing = stagingCol.fields.filter((field) => !field.data && field.type.split('.')[0] === 'input');
			if (missing.length > 0) {
				return true;
			} else {
				return false;
			}
		}

		if (!data && checkMissingData()) {
			handleFadeOutModal({
				message: 'One or more fields are incomplete',
				isFading: false,
				show: true,
			});
			return;
		}

		if (data) {
			data.teamUuid = teamState.currentTeam.teamUuid;
		}

		let docketData = {
			name: chosenDocket.name || `Docket #${dockets?.length + 1}`,
			teamUuid: teamState.currentTeam.teamUuid,
			published: chosenDocket.published,
			default: chosenDocket.default,
			fields: stagingCol.fields,
			docketType: 1,
		};

		let res = await Axios({
			url: `${config.apiv1}/docket/docket.create`,
			method: 'POST',
			data: data || docketData,
		});

		if (res?.data?.ok) {
			setChosenDocket(res?.data?.data);
			dockets.push(res.data.data);

			handleFadeOutModal({
				message: 'Form saved successfully',
				isFading: false,
				show: true,
			});
		} else {
			handleFadeOutModal({
				message: 'Error when saving form',
				isFading: false,
				show: true,
			});
		}
	}

	async function patchDocket(data, combineData) {
		let docketData = {
			fields: stagingCol.fields,
			default: chosenDocket.default,
			published: chosenDocket.published,
			teamUuid: teamState.currentTeam.teamUuid,
			docketType: chosenDocket.docketType,
			name: chosenDocket.name,
		};
		if (data) {
			data.teamUuid = teamState.currentTeam.teamUuid;
			if (combineData === true) {
				data = { ...docketData, ...data };
			}
		}

		let res = await Axios({
			url: `${config.apiv1}/docket/docket.update/${data?.docketUuid || chosenDocket.docketUuid}`,
			method: 'PATCH',
			data: data || docketData,
		});

		if (res?.data?.ok) {
			if (data?.default || docketData?.default) {
				for (let i = 0; i < dockets.length; i++) {
					if (
						dockets[i].default === true &&
						data?.docketUuid !== dockets[i].docketUuid &&
						data?.docketType === dockets[i].docketType
					) {
						dockets[i].default = false;
					}
				}
			}
			if (data?.parentUuid || docketData?.parentUuid) {
				for (let i = 0; i < dockets.length; i++) {
					if (data?.docketUuid !== dockets[i].docketUuid && data.parentUuid === dockets[i].parentUuid) {
						dockets[i].parentUuid = '';
					}
				}
			}

			let updtDockets = dockets.map((docket, key) => {
				if (data) {
					if (docket.docketUuid === data?.docketUuid) {
						return res.data.data;
					}
				} else if (docket.docketUuid === chosenDocket?.docketUuid) {
					return res.data.data;
				}
				return docket;
			});

			setChosenDocket(res.data.data);
			setDockets(() => [...updtDockets]);
			setChangeMade(false);

			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'Form updated successfully',
			});
		} else {
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'Error when updating form',
			});
		}
	}

	async function deleteDocket(docketUuid) {
		let res = await Axios({
			url: `${config.apiv1}/docket/docket.delete/${docketUuid}`,
			method: 'DELETE',
		});
		if (res?.data?.ok) {
			let updtDockets = [...dockets];
			const docketIndex = dockets.map((docket) => docket?.docketUuid).indexOf(docketUuid);
			updtDockets.splice(docketIndex, 1);
			setDockets(updtDockets);

			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'Form deleted successfully',
			});
		} else {
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'Error when deleting form',
			});
		}
	}

	const handleChosenDocket = (uuid) => {
		const staging = Object.assign(stagingCol, {});
		if (uuid) {
			const docket = dockets.filter((docket) => docket.docketUuid === uuid);
			staging.fields = docket[0].fields;
			setStagingCol(staging);
			setChosenDocket(docket[0]);
		} else {
			staging.fields = [];
			setStagingCol(staging);
			setChosenDocket({ name: `Form-New#${dockets.length + 1}` });
			setShowDropZones(true);
		}
	};

	const checkIfDupeBehaviorAndCorrect = (fieldName, colId) => {
		for (let i = 0; i < stagingCol.fields?.length; i++) {
			if (stagingCol.fields[i]?.data && stagingCol.fields[i]?.data[fieldName] === true) {
				let newFieldData = { field: { ...stagingCol.fields[i] }, colId: colId };
				newFieldData.field.data[fieldName] = false;
				return insertNewColFieldData(stagingCol, newFieldData);
			}
		}
		return false;
	};

	async function getDockets() {
		const res = await Axios({
			url: `${config.apiv1}/docket/dockets.read/${teamState.currentTeam.teamUuid}`,
			method: 'GET',
		});

		if (res?.data?.ok) {
			if (res.data?.data) {
				setDockets(res?.data?.data);
			}
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			getDockets();
		}
		return () => (mounted = false);
	}, []);

	useEffect(() => {
		if (windowSize?.width <= 740 && !modalsState?.smallScreenModal?.open) {
			handleModal('smallScreenModal', {
				callback: () => Navigate('/'),
				header: 'Your device is too small to display this tool properly.',
				open: true,
			});
			return;
		} else if (windowSize?.width > 740 && modalsState?.smallScreenModal?.open) {
			handleModal('smallScreenModal', {
				open: false,
			});
		}
	}, [windowSize?.width]);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			let newTools = Object.assign(toolCol, {});
			if (selected === 1) {
				newTools.fields = widgets;
			} else if (selected === 2) {
				newTools.fields = buildFields;
			} else {
				newTools.fields = [];
			}

			setToolCol((toolCol) => ({ ...toolCol, fields: newTools.fields }));
		}
		return () => (mounted = false);
	}, [selected, dockets]);

	return (
		<>
			{modalsState?.deleteTempModal?.open && (
				<ConfirmModal
					value={modalsState?.deleteTempModal.value}
					header={'Are you sure you want to delete this template?'}
					callback={modalsState?.deleteTempModal.callback}
					handleModal={handleModal}
					modalType={'deleteTempModal'}
					zIndex={999}
				/>
			)}
			{modalsState?.colorModal?.open && (
				<FlexModal
					setShow={handleModal}
					modalName={'colorModal'}
					zIndex={99}
					btn1='Save'
					closeBtn={true}
					callback={() => {
						patchDocket({
							docketUuid: modalsState?.colorModal?.value?.docketUuid,
							color: color,
						});
						handleModal('colorModal', false);
						handleFadeModal('modalFading', {
							message: 'Color successfully changed',
							isFading: false,
							show: true,
						});
					}}
					body={
						<ColorPicker
							template={modalsState?.colorModal?.value}
							setColor={setColor}
							colors={colors}
							color={color}
						/>
					}
				/>
			)}
			{modalsState?.hintOptionsModal?.open && (
				<FlexModal
					setShow={() => {
						handleModal();
						setFormData({});
					}}
					modalName={'hintOptionsModal'}
					zIndex={99}
					btn1='Save'
					closeBtn={true}
					callback={() => {
						patchDocket({
							docketUuid: modalsState?.hintOptionsModal?.docket?.docketUuid,
							hintFormat: formData?.hintFormat,
						});
						handleModal('hintOptionsModal', false);
						setFormData({});
						handleFadeModal('modalFading', {
							message: 'Hint format successfully updated',
							isFading: false,
							show: true,
						});
					}}
					body={
						<FieldSuggestions
							suggestions={modalsState?.hintOptionsModal?.docket?.fields
								?.filter((field) => allowedTypes.includes(field.type))
								?.map((field) => field?.data?.fieldName)}
							value={formData?.hintFormat || modalsState?.hintOptionsModal?.docket?.hintFormat}
							handleFormInput={handleFormInput}
							customErrors={customErrors}
							formData={formData}
							tooltip={
								'This option causes the seller hint to be pre-filled on customer order creation with any combination of the text fields present on your custom form.'
							}
							text='Hint format'
							name='hintFormat'
							includeSpace
						/>
					}
				/>
			)}
			{modalsState?.linkModal?.open && (
				<FlexModal
					setShow={handleModal}
					modalName={'linkModal'}
					zIndex={99}
					btn1='Save'
					closeBtn={true}
					callback={() => {
						patchDocket({
							docketUuid: modalsState?.linkModal?.value?.docketUuid,
							parentUuid: parentUuid,
						});
						handleModal('linkModal', false);
						handleFadeModal('modalFading', {
							message: 'Link successful',
							isFading: false,
							show: true,
						});
					}}
					body={
						<SelectInput
							callback={(e) => {
								setParentUuid(e.value);
							}}
							value={modalsState?.linkModal?.value?.parentUuid}
							name={'assignment'}
							options={parseToOptions(
								dockets.filter((docket) => docket.docketType === 1),
								'dockets'
							)}
						/>
					}
				/>
			)}
			{modalsState.deleteModal?.open === true && (
				<>
					<ConfirmModal
						callback={modalsState['deleteModal'].callback}
						header={modalsState['deleteModal'].header}
						value={modalsState['deleteModal'].value}
						handleModal={handleModal}
						modalType={'deleteModal'}
						zIndex={999}
					/>
				</>
			)}
			{modalsState.smallScreenModal?.open === true && (
				<>
					<ProcessingModal
						show={modalsState.smallScreenModal?.open}
						loading={false}
						message={
							<>
								Your screen width is too small to view this page. <br />
								<br />
								<Link to='/' className='linkTextTurq'>
									<>Return to dashboard</>
								</Link>
							</>
						}
					/>
					<Overlay loadOverlay={true} showOverlay={true} zIndex={96} />
				</>
			)}
			{!modalsState.smallScreenModal?.open && (
				<div className='docketBuildCont' style={{ backgroundColor: isEditing ? '#D6D6D6' : 'white' }}>
					<FadeOutModal
						isFading={modalsOpen['modalFading']?.isFading}
						message={modalsOpen['modalFading']?.message}
						show={modalsOpen['modalFading']?.show}
					/>
					{isEditing ? (
						<DragDropContext
							onBeforeCapture={onBeforeCapture}
							onDragUpdate={onDragUpdate}
							onDragStart={onDragStart}
							onDragEnd={onDragEnd}>
							<StagingCol
								checkIfDupeBehaviorAndCorrect={checkIfDupeBehaviorAndCorrect}
								handleChosenDocket={handleChosenDocket}
								disablePlaceholder={disablePlaceholder}
								handleSelectField={handleSelectField}
								handleDocketInput={handleDocketInput}
								setEditingFields={setEditingFields}
								handleFormInput={handleFormInput}
								addSelectField={addSelectField}
								dropsDisabled={dropsDisabled}
								editingFields={editingFields}
								showDropZones={showDropZones}
								setChangeMade={setChangeMade}
								setIsEditing={setIsEditing}
								chosenDocket={chosenDocket}
								customErrors={customErrors}
								remove={removeFieldPrompt}
								handleModal={handleModal}
								setSelected={setSelected}
								setFormData={setFormData}
								patchDocket={patchDocket}
								handleFile={handleFile}
								postDocket={postDocket}
								popOption={popOption}
								save={saveFieldData}
								selected={selected}
								addRadio={addRadio}
								formData={formData}
								column={stagingCol}
								dockets={dockets}
							/>
							<ToolsCol
								disablePlaceholder={disablePlaceholder}
								handleChosenDocket={handleChosenDocket}
								handleDocketInput={handleDocketInput}
								setDocketData={setDocketData}
								dropsDisabled={dropsDisabled}
								showDropZones={showDropZones}
								setIsEditing={setIsEditing}
								chosenDocket={chosenDocket}
								setSelected={setSelected}
								handleModal={handleModal}
								patchDocket={patchDocket}
								changeMade={changeMade}
								postDocket={postDocket}
								selected={selected}
								dockets={dockets}
								column={toolCol}
							/>
						</DragDropContext>
					) : (
						<Library
							handleChosenDocket={handleChosenDocket}
							handleDropdown={handleDropdown}
							externalTemps={externalTemps}
							dropdownOpen={dropdownOpen}
							setIsEditing={setIsEditing}
							deleteDocket={deleteDocket}
							patchDocket={patchDocket}
							handleModal={handleModal}
							postDocket={postDocket}
							setColor={setColor}
							teamTemps={dockets}
							color={color}
						/>
					)}
				</div>
			)}
		</>
	);
}
