import React from 'react';
import { useNavigate } from 'react-router';
import { ClipboardArrowDownIcon, ClipboardArrowsClockwiseIcon, ThreeDotsIcon } from '../SVGIcons/SVGIcons';
import { dateConverter, parseExactDate } from '../../helpers';

export default function OrderNotification(props) {
	const {
		notification,
		setRead,
		checkHref,
		deleteNotification,
		style,
		handleDropdowns,
		dropdownsOpen,
		rightSidebarDispatch,
	} = props || {};
	const { notificationData } = notification;

	let navigate = useNavigate();
	return (
		<>
			<div
				className={`mainRow ${notification.isRead ? 'opacity-50' : ''}`}
				onClick={() => {
					setRead(notification?.notificationUuid);
					checkHref(`/orders/${notificationData.orderUuid}?orderRef=${notificationData?.orderRef}`);
					navigate(`/orders/${notificationData.orderUuid}?orderRef=${notificationData?.orderRef}`);
					rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: false });
				}}>
				<div className={`iconCol ${style}`}>
					{style === 'sale' ? (
						<ClipboardArrowDownIcon iconClass='notificationIcon' />
					) : (
						<ClipboardArrowsClockwiseIcon iconClass='notificationIcon' />
					)}
				</div>
				{notification.notificationType === 'order.created' && (
					<div className='bodyCol'>
						<div className='colRowTop'>
							<h4 className={`notificationHeader ${style}`}>{`NEW ORDER RECEIVED`}</h4>
							<h4 className='notificationDate'>{parseExactDate(notification.createdAt)}</h4>
						</div>
						<div className='colRowBottom'>
							<p className='bodyText'>{`New order '${notificationData.orderRef}' from ${notification.senderTeamName}`}</p>
							<ThreeDotsIcon
								iconClass='notificationSubIcon'
								onClick={(e) => {
									e.stopPropagation();
									handleDropdowns(notification?.notificationUuid);
								}}
							/>
						</div>
					</div>
				)}
				{notification.notificationType === 'order.updated' && (
					<div className='bodyCol'>
						<div className='colRowTop'>
							<h4 className={`notificationHeader ${style}`}>{`ORDER UPDATED`}</h4>
							<h4 className='notificationDate'>
								{notification.createdAt ? parseExactDate(notification?.createdAt) : ''}
							</h4>
						</div>
						<div className='colRowBottom'>
							<p className='bodyText'>
								{notificationData?.updatedFields?.hold?.confirmationRequired?.isDone === true ? (
									<>
										{`${notification.senderTeamName} has marked`}{' '}
										<span className='highlight warning'>hold</span>{' '}
										{`task as done for order '${notificationData.orderRef}'`}
									</>
								) : (
									<>
										{`Order '${notificationData.orderRef}' ${
											notificationData?.updatedFields?.hold?.isHold
												? `was put on `
												: 'has been updated'
										}`}
										{notificationData?.updatedFields?.hold?.isHold && (
											<span className='highlight warning'>hold</span>
										)}
									</>
								)}
							</p>
							<ThreeDotsIcon
								iconClass='notificationSubIcon'
								onClick={(e) => {
									e.stopPropagation();
									handleDropdowns(notification?.notificationUuid);
								}}
							/>
						</div>
					</div>
				)}
				{notification.notificationType === 'order.priceSuggested' && (
					<div className='bodyCol'>
						<div className='colRowTop'>
							<h4 className={`notificationHeader ${style}`}>{`PRICE SUGGESTED`}</h4>
							<h4 className='notificationDate'>{parseExactDate(notification.createdAt)}</h4>
						</div>
						<div className='colRowBottom'>
							<p className='bodyText'>{`Price suggestion from '${notification.senderTeamName}' regarding order '${notificationData.orderRef}' needs review`}</p>
							<ThreeDotsIcon
								iconClass='notificationSubIcon'
								onClick={(e) => {
									e.stopPropagation();
									handleDropdowns(notification?.notificationUuid);
								}}
							/>
						</div>
					</div>
				)}
			</div>
			{dropdownsOpen && (
				<>
					{dropdownsOpen[notification?.notificationUuid] && (
						<div className='notificationDropdown'>
							<p
								onClick={() => {
									deleteNotification(notification);
								}}>
								Delete
							</p>
							<p
								onClick={() => {
									setRead(notification?.notificationUuid);
								}}>
								Mark as read
							</p>
						</div>
					)}
				</>
			)}
		</>
	);
}
