import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import ManageUserProfileForm from '../../components/Forms/ManageUserProfileForm';
import config from '../../config';
import Button from '../../components/Button';
import { splitUserId } from '../../helpers';
import useFetch from '../../hooks/useFetch';
import ChangePassForm from '../../components/Forms/ChangePassForm';
import AvatarImg from '../../components/AvatarImg';
import Tabs from '../../components/Tabs';
import { AtIcon, BellIcon, Checkmark, LockIcon, UserCogIcon, UserIcon } from '../../components/SVGIcons/SVGIcons';
import TabsWrapper from '../../components/Tabs/TabsWrapper';
import ManageUserCommsForm from '../../components/Forms/ManageUserCommsForm';

export default function UserManage({ hideModal }) {
	const { userState } = useContext(CurrentUserContext);

	const [user, setUser] = useState();
	const [selectedTab, setSelectedTab] = useState('profile');

	const { fetch } = useFetch();

	const handleSelect = (name) => {
		setSelectedTab(name);
	};

	useEffect(async () => {
		let res = await fetch(`${config.auth}/v1/user/user.read/${splitUserId(userState?.currUser?.sub)}`, null, true);
		if (res?.user) {
			let user = res.user;
			user.avatar = userState.currUser.avatar;
			setUser(user);
		}
	}, []);

	return (
		<div className='userManageCont'>
			<div className='header'>
				<h2>
					<AvatarImg user={userState?.currUser} imageOptions={{ ariaHidden: true }} />
					Account
				</h2>
				<div className='marginLeft-auto'>
					<Button
						text='Done'
						style='secondary'
						iconLeft={<Checkmark />}
						size='lg'
						minWidth={120}
						onClick={hideModal}
					/>
				</div>
			</div>
			<Tabs
				tabItems={[
					{ name: 'profile', icon: <UserCogIcon iconClass='tabIcon' />, text: 'Profile' },
					{ name: 'communication', icon: <AtIcon iconClass='tabIcon' />, text: 'Communication' },
					{ name: 'password', icon: <LockIcon iconClass='tabIcon' />, text: 'Password' },
					{
						name: 'notifications',
						icon: <BellIcon iconClass='tabIcon' />,
						text: 'Notifications',
						disabled: true,
					},
				]}
				handleSelect={handleSelect}
				selectedTab={selectedTab}
			/>
			<TabsWrapper style={{ padding: '40px 32px' }}>
				{selectedTab === 'profile' ? (
					<>
						<ManageUserProfileForm user={user} setUser={setUser} hideModal={hideModal} />
					</>
				) : (
					<></>
				)}
				{selectedTab === 'communication' ? <ManageUserCommsForm user={user} setUser={setUser} /> : <></>}
				{selectedTab === 'password' ? <ChangePassForm /> : <></>}
			</TabsWrapper>
		</div>
	);
}
