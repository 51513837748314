import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { SocketContext } from '../App';
import config from '../config';
import { CurrentTeamContext } from './CurrentTeamContext';
import OneSignal from 'react-onesignal';
import usePost from '../hooks/usePost';
import { CurrentUserContext } from './CurrentUserContext';
import { splitUserId } from '../helpers';

let NotificationContext = React.createContext();

function NotificationContextProvider(props) {
	let { teamState } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);
	const socket = useContext(SocketContext);

	const [initialized, setInitialized] = useState(false);

	const { post } = usePost(null, null, null, 'PATCH');

	let initialState = {
		loading: true,
		reload: false,
		notifications: [],
		page: 0,
		unreadNotifications: false,
	};

	const reducer = (notificationsState, action) => {
		let index;
		let unreadNotifications;
		let newState;

		switch (action.type) {
			case 'ADD_NOTIFICATION':
				index = notificationsState.notifications.findIndex((notification) => {
					return notification.notificationUuid === action.payload.notificationUuid;
				});
				if (index < 0) {
					action.payload.isRead = false;
					notificationsState.notifications.unshift(action.payload);
					if (!notificationsState.unreadNotifications) {
						notificationsState.unreadNotifications = true;
					}
				}
				return {
					...notificationsState,
				};
			case 'ACK_NOTIFICATION':
				index = notificationsState.notifications.findIndex((notification) => {
					return notification.notificationUuid === action.payload.notificationUuid;
				});
				if (index > -1) {
					notificationsState.notifications.splice(index, 1);
				}
				return {
					...notificationsState,
					reload: true,
				};
			case 'SET_LOADING':
				return {
					...notificationsState,
					loading: action.payload || false,
				};
			case 'RELOAD':
				return {
					...notificationsState,
					reload: true,
				};
			case 'SET_PAGE':
				newState = { ...notificationsState, page: action.payload };
				fetchNotifications(newState);
				return {
					...newState,
				};
			case 'SET_NEW_NOTIFICATIONS':
				newState = { ...notificationsState, ...action.payload };
				return {
					...newState,
				};
			case 'SET_READ':
				patchNotification(action.payload);
				index = notificationsState.notifications.findIndex((notification) => {
					return notification.notificationUuid === action.payload;
				});
				if (index > -1) {
					if (notificationsState.notifications[index].isRead !== true) {
						notificationsState.notifications[index].amount--;
						notificationsState.notifications[index].isRead = true;
					}
				}
				unreadNotifications = false;
				for (let notification of notificationsState.notifications) {
					if (!notification.isRead) {
						unreadNotifications = true;
					}
				}
				return {
					...notificationsState,
					unreadNotifications: unreadNotifications,
				};
			case 'SET_ALL_MESSAGES_READ':
				for (let i = 0; i < notificationsState.notifications.length; i++) {
					if (
						notificationsState.notifications[i].notificationData.orderUuid === action.payload &&
						notificationsState.notifications[i].isRead === false
					) {
						patchNotification(notificationsState.notifications[i].notificationUuid);
						notificationsState.notifications[i].isRead = true;
					}
				}
				unreadNotifications = false;
				for (let notification of notificationsState.notifications) {
					if (!notification.isRead) {
						unreadNotifications = true;
					}
				}
				return {
					...notificationsState,
					unreadNotifications: unreadNotifications,
				};
			case 'SET_UNREAD':
				return {
					...notificationsState,
					unreadNotifications: action.payload,
				};
			default:
				return {
					...notificationsState,
				};
		}
	};

	let [notificationsState, notificationsDispatch] = React.useReducer(reducer, initialState);
	let value = { notificationsState, notificationsDispatch };

	async function patchNotification(notificationUuid) {
		let res = await Axios({
			url: `${config.apiv1}/notification/notification.update/${notificationUuid}`,
			method: 'PATCH',
			data: {
				isRead: true,
			},
		});
		if (res.ok) {
			return true;
		} else {
			return false;
		}
	}

	useEffect(() => {
		// let interval;
		let mounted = true;

		if (!teamState?.currentTeam?.teamUuid) {
			return;
		}

		OneSignal.init({
			appId: config.onesignalAppId,
			allowLocalhostAsSecureOrigin: process.env.REACT_APP_ENV === 'development-local' ? true : false,
			notifyButton: {
				enable: true,
			},
		})
			.then(async () => {
				OneSignal.setConsentRequired(true);
				OneSignal.Slidedown.promptPush();
				// OneSignal.Debug.setLogLevel('trace');
				setInitialized(true);
				OneSignal.setConsentGiven(true);
				const oneSignalId = OneSignal?.User?.PushSubscription?.id;
				if (oneSignalId) {
					post(
						{ oneSignalId: oneSignalId },
						`${config.auth}/v1/user/user.update/${splitUserId(userState?.currUser?.sub)}`
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});

		if (socket !== null && mounted) {
			fetchNotifications(notificationsState);

			socket.on('new-notification', async (notification) => {
				let chat = document.getElementById('chat');
				if (notification.notificationType === 'message.created' && !chat) {
					// socket.emit('create-notification', notification);
					notificationsDispatch({
						type: 'ADD_NOTIFICATION',
						payload: {
							...notification,
						},
					});
				} else if (notification.notificationType !== 'message.created') {
					// socket.emit('create-notification', notification);
					notificationsDispatch({
						type: 'ADD_NOTIFICATION',
						payload: {
							...notification,
						},
					});
				}
			});
			// function flashTitle(pageTitle, newTitle) {
			// 	if (document.title === pageTitle) {
			// 		document.title = newTitle;
			// 	} else {
			// 		document.title = pageTitle;
			// 	}
			// }
			// interval = setInterval(() => {
			// 	if (!notificationsState.unreadNotifications) {
			// 		flashTitle('Procuur', `New notification(s)!`);
			// 	}
			// }, 3000);
		}
		return () => {
			// clearInterval(interval);
			mounted = false;
		};
	}, [socket, notificationsState.reload]);

	function fetchNotifications(newNotificationsState) {
		Axios({
			url: `${config.apiv1}/notification/notifications.read/${
				teamState.currentTeam.teamUuid
			}?sortDate=desc&limit=20&skip=${
				newNotificationsState?.page ? newNotificationsState?.page * 20 : 0
			}&userUuid=${splitUserId(userState?.currUser?.sub)}`,
			method: 'GET',
		})
			.catch((err) => {
				notificationsDispatch({ type: 'SET_LOADING', payload: false });
				return err;
			})
			.then((res) => {
				let unread = false;
				if (res?.data?.ok) {
					let newNotifications = [];
					for (let i = 0; i < res.data.data.length; i++) {
						if (!res.data.data[i].isRead) {
							unread = true;
						}
						newNotifications.push(res.data.data[i]);
					}
					newNotifications.sort((a, b) => {
						if (a.isRead && !b.isRead) {
							return 1;
						}
						if (!a.isRead && b.isRead) {
							return -1;
						}
						return 0;
					});
					notificationsDispatch({
						type: 'SET_NEW_NOTIFICATIONS',
						payload: { notifications: newNotifications, totalNotifications: res?.data?.totalDocuments },
					});
					notificationsDispatch({ type: 'SET_UNREAD', payload: unread });
				} else {
					notificationsDispatch({ type: 'SET_LOADING', payload: false });
				}
			});
	}

	return <NotificationContext.Provider value={value}>{props.children}</NotificationContext.Provider>;
}

let NotificationContextConsumer = NotificationContext.Consumer;

export { NotificationContext, NotificationContextProvider, NotificationContextConsumer };
