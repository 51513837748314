import React, { useContext, useEffect, useState } from 'react';
import { ErrorIcon, UserCancelIcon } from '../../components/SVGIcons/SVGIcons';
import CheckboxInput from '../../components/Inputs/CheckboxInput';
import Button from '../../components/Button';
import { useLocation } from 'react-router';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import usePost from '../../hooks/usePost';
import config from '../../config';
import Loading from '../../components/Loading/Loading';
import { splitUserId } from '../../helpers';

export default function UserDelete() {
	const { userState } = useContext(CurrentUserContext);

	let location = useLocation();
	let type = new URLSearchParams(location.search).get('pageContentType');
	let deleteRequestUuid = new URLSearchParams(location.search).get('deleteRequestUuid');

	const [loading, setLoading] = useState(false);
	const [agreed, setAgreed] = useState(false);
	const [pageContentType, setPageContentType] = useState(type || 'default');

	const { post } = usePost();

	async function requestDeletion() {
		if (agreed === true) {
			let res = await post(
				{ userUuid: splitUserId(userState?.currUser?.sub) },
				`${config.auth}/v1/email/user.anonymize`
			);
			setPageContentType('emailSent');
		}
	}

	useEffect(async () => {
		setLoading(true);
		if (type === 'confirmed' && deleteRequestUuid) {
			let res = await post({ deleteRequestUuid }, `${config.auth}/v1/user/user.anonymize`);
			if (res?.data?.ok === false) {
				setPageContentType('expired');
			}
		} else if (type === 'cancelled' && deleteRequestUuid) {
			let res = await post(
				{ deleteRequestUuid },
				`${config.auth}/v1/user/user.cancel-anonymize`,
				false,
				'DELETE'
			);
		}
		setTimeout(() => {
			setLoading(false);
		}, 500);
	}, [location]);

	return (
		<div className='pageInnerCont white'>
			{loading === true ? (
				<Loading type='circle' />
			) : (
				<div className='appManageCont'>
					<div className='mainHeader'>
						<h1 style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
							<UserCancelIcon iconClass='headerIcon' />
							Close your Procuur account
						</h1>
					</div>
					{pageContentType === 'default' ? (
						<>
							<h3 className='marginTop-20 marginBottom-10'>Please read this carefully</h3>
							<p>
								You are about to submit a request for us to permanently close your Procuur account and
								delete your data. Once your account is closed all of the products and services accessed
								through your account will no longer be available to you. For example, submitting your
								account closure request through this website will also close your account on
								procuur.com, and all other global sites to the extent you use the same credentials to
								access services and products offered through those sites.{' '}
							</p>
							<p>
								If you have uploaded your own content in one of our services (e.g. uploading photos or
								videos), you may want to download that content before closing your account. They will
								not be accessible to you after you close your account.
							</p>
							<h3 className='flexRow marginTop-20' style={{ color: '#ef6820' }}>
								Account Closure Is A Permanent Action
							</h3>
							<p>
								Please note account closure is a permanent action and once your account is closed it
								will no longer be available to you and{' '}
								<span className='spanBold'>cannot be restored</span>. If you decide later that you want
								to start ordering from us again, or if you would like to use products and services that
								require an account, you will need to create a new account.
							</p>
							<CheckboxInput
								callback={() => setAgreed(!agreed)}
								checked={agreed === true}
								checkboxText='Yes, I want to permanently close my Procuur Account and delete my data.'
								onlyInput
							/>
							<div className='btnCont'>
								<Button
									text='Close my account'
									style='destructive'
									size='md'
									onClick={() => {
										requestDeletion();
									}}
									disabled={agreed === false}
								/>
							</div>
							<p>
								Please be advised that Procuur is legally required or entitled to retain some types of
								data, such as order history. We do this in line with applicable laws including for tax
								and accounting and fraud prevention purposes.
							</p>
						</>
					) : (
						<></>
					)}
					{pageContentType === 'emailSent' ? (
						<>
							<h3 className='marginTop-20 marginBottom-10'>Confirmation Required</h3>
							<p>
								Protecting your privacy and the security of your data is a top priority for Procuur. You
								will receive a confirmation link via email to verify your request to close your Procuur
								account and delete your data. Please note, this link will expire in 5 days.
							</p>
						</>
					) : (
						<></>
					)}
					{pageContentType === 'cancelled' ? (
						<>
							<h3 className='marginTop-20 marginBottom-10'>
								Your Request To Close Your Procuur Account Was Cancelled.
							</h3>
							<p>
								Please note that we've cancelled your request to close your Procuur account and delete
								your data.
							</p>
						</>
					) : (
						<></>
					)}
					{pageContentType === 'confirmed' ? (
						<>
							<h3 className='marginTop-20 marginBottom-10'>
								Your Request To Close Your Procuur Account Was Confirmed.
							</h3>
							<p>Please note that we've closed your Procuur account and deleted your data.</p>
						</>
					) : (
						<></>
					)}
					{pageContentType === 'expired' ? (
						<>
							<h3 className='marginTop-20 marginBottom-10'>
								Your Request To Close Your Procuur Account Was Rejected.
							</h3>
							<p>This request was rejected because the request is either invalid or expired.</p>
						</>
					) : (
						<></>
					)}
				</div>
			)}
		</div>
	);
}
