import React from 'react';
import TextInput from '../../../components/Inputs/TextInput';

export default function FieldSuggestions({
	suggestions,
	name,
	text,
	formData,
	handleFormInput,
	customErrors,
	tooltip,
	value,
	includeSpace,
}) {
	function checkInputFormat(formatValue, key) {
		let currentValue = formData[key];
		if (!currentValue) {
			currentValue = value;
		}
		if (currentValue !== null && currentValue !== undefined && currentValue !== 'undefined') {
			return `${currentValue}${includeSpace ? ' ' : ''}{{${formatValue}}}`;
		} else {
			return `{{${formatValue}}}`;
		}
	}

	return (
		<>
			<TextInput
				text={text}
				name={name}
				callback={handleFormInput}
				value={value}
				customError={customErrors[name]}
				tooltip={tooltip}
				controlled
			/>
			<div className='suggestionsCont'>
				{suggestions?.map((suggestion, key) => (
					<div
						className='suggestion'
						key={key}
						onClick={() => {
							handleFormInput(checkInputFormat(suggestion, name), name);
						}}>
						{suggestion}
					</div>
				))}
			</div>
		</>
	);
}
