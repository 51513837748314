import { useState, useCallback, useContext, useEffect } from 'react';
import test from '../../assets/img/transactor-logo.PNG';
import CropperTool from '../Cropper';
import FileInput from '../Inputs/FileInput';
import useFetch from '../../hooks/useFetch';
import { ModalContext } from '../../contexts/ModalContext';
import * as yup from 'yup';
import { useForms } from '../../hooks/useForms';
import config from '../../config';
import { checkUploadFileTypeAllowed, splitUserId } from '../../helpers';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import Axios from 'axios';

export default function EditAvatarForm({ hideModal }) {
	const [img, setImg] = useState(null);

	const { fetch } = useFetch();

	const { flashFadeModal } = useContext(ModalContext);
	const { userState, userDispatch } = useContext(CurrentUserContext);

	const handleUpload = async (file) => {
		const maxFileSize_OLD = 1000 * 1000 * 20;
		const maxFileSize = 100 * 1000;

		if (!checkUploadFileTypeAllowed(file.type, ['image/png', 'image/jpeg', 'image/jpg'])) {
			return flashFadeModal('Invalid file type. Allowed file types include: JPEG, PNG');
		}
		if (file.size > maxFileSize) {
			return flashFadeModal(`File is too large. Maximum allowed size is ${maxFileSize / 1000 / 1000}MB`);
		}
		let newPath = window.URL.createObjectURL(new Blob([file]));
		let formData = new FormData();
		formData.append('avatar', file);
		formData.append('userUuid', splitUserId(userState?.currUser?.sub));

		const res = await Axios({
			url: `${config.auth}/v1/user/upload.avatar`,
			method: 'PATCH',
			data: formData,
		});

		if (res?.data?.ok === true) {
			let createdAvatar = res?.data?.user?.avatar;
			setImg(null);
			userDispatch({ type: 'SET_USER_DATA', payload: { avatar: { ...createdAvatar, url: newPath } } });
			hideModal();
			return flashFadeModal('Your avatar was successfully updated!');
		} else {
			return flashFadeModal('There was an error when trying to update your avatar. Please contact support');
		}
	};

	return (
		<div>
			<CropperTool img={img} setImg={setImg} handleUpload={handleUpload} />
		</div>
	);
}
