import React, { useEffect, useState } from 'react';
import { CaretDownIcon, EnvelopeIcon, PenIcon } from '../../../components/SVGIcons/SVGIcons';
import {
	checkStatusIfDisable,
	filterMember,
	makeFirstCharUpper,
	parseOrderPriority,
	parseProcessingStatus,
	sliceString,
	splitUserId,
} from '../../../helpers';

export default function OrderReferences(props) {
	const {
		order,
		userState,
		teamState,
		isSeller,
		newOrderData,
		parentOrder,
		dropdownOpen,
		handleNewOrderData,
		handleDropdown,
		setShowOverlay,
		handleModal,
		patchOrder,
	} = props?.props || {};

	const [member, setMember] = useState(null);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			for (let i = 0; i < teamState.currentTeam.members?.length; i++) {
				if (
					teamState.currentTeam.members[i].userUuid === order.sellerUserUuid ||
					teamState.currentTeam.members[i].userUuid === newOrderData.sellerUserUuid
				) {
					setMember(teamState.currentTeam.members[i]);
				}
			}
		}
		return () => (mounted = false);
	}, [order]);

	return (
		<div className='gridItemOrder references'>
			<div className='row highlight'>
				<h4 className='gridHeader'>References</h4>
				<div className='gridIconCont'>
					{isSeller && (
						<>
							{checkStatusIfDisable(order.orderProcessingStatus, 'editOrder') ? (
								<div className='gridIconRegular discIcon disabledSVG'>
									<PenIcon />
								</div>
							) : (
								<div
									className='gridIconRegular discIcon'
									onClick={() => {
										handleModal('editModal', { open: true, type: 1 });
										setShowOverlay(true);
									}}>
									<PenIcon />
								</div>
							)}
						</>
					)}
				</div>
			</div>
			<div className='row highlight'>
				<p className='text'>Order Ref.</p> <div className='data bold'>{order?.orderRef}</div>
			</div>
			{order?.sellerHint && isSeller && (
				<div className='row'>
					<p className='text'>Hint: </p>
					<div className='data bold'>{sliceString(order?.sellerHint, 0, 100)}</div>
				</div>
			)}
			{order?.buyerHint && !isSeller && (
				<div className='row'>
					<p className='text'>Hint: </p>
					<div className='data bold'>{sliceString(order?.buyerHint, 0, 100)}</div>
				</div>
			)}
			<div className='row'>
				<p className='text'>Placed by </p>
				<div className='data bold'>
					{order?.buyerUserName?.charAt(0).toUpperCase() + order?.buyerUserName?.slice(1)}
				</div>
			</div>
			<div className='row'>
				<p className='text'>On behalf of </p>
				<div className='data bold'>
					{order?.behalfOf?.name?.charAt(0).toUpperCase() + order?.behalfOf?.name?.slice(1)}
				</div>
			</div>
			{order.sellerUserUuid &&
				isSeller &&
				filterMember(teamState?.currentTeam?.members, order?.sellerUserUuid) && (
					<div className='row'>
						<p className='text'>Assigned to </p>
						<div className='data bold'>
							{filterMember(teamState?.currentTeam?.members, order?.sellerUserUuid) || '(none)'}
						</div>
					</div>
				)}
			{order?.parentUuid && (
				<div className='row'>
					<p className='text'>Parent Order </p>
					<div className='data bold'>{parentOrder?.orderRef}</div>
				</div>
			)}

			{/* <div className='row'>
				<p className='text'>Order Priority </p>
				<div className='data bold'>{parseOrderPriority(order?.priority)}</div>
			</div> */}
			<div className='btnCont'>
				{!checkStatusIfDisable(order?.orderProcessingStatus, 'assign') && isSeller && (
					<>
						{!order.sellerUserUuid && !newOrderData.sellerUserUuid ? (
							<button
								className={`btn3-split`}
								onClick={() => {
									patchOrder(
										{
											sellerUserUuid: splitUserId(userState.currUser.sub),
										},
										false,
										true
									);
									handleDropdown('assignee', false);
								}}>
								<>Take it</>
								<div
									className='caretCont'
									onClick={(e) => {
										e.stopPropagation();
										handleDropdown('assignee', !dropdownOpen.assignee);
									}}>
									<CaretDownIcon iconClass='caret' />
								</div>
							</button>
						) : (
							<button
								className={`btn3-split${
									newOrderData.sellerUserUuid === order.sellerUserUuid || !member ? ' noHover' : ''
								}`}
								onClick={() => {
									member?.userUuid !== order.sellerUserUuid && newOrderData.sellerUserUuid
										? patchOrder(
												{
													sellerUserUuid:
														newOrderData?.sellerUserUuid || order?.sellerUserUuid,
												},
												false,
												true
										  )
										: null;
									handleDropdown('assignee', false);
								}}>
								{newOrderData.sellerUserUuid && newOrderData.sellerUserUuid !== order.sellerUserUuid ? (
									<>Assign to </>
								) : (
									<>{order?.sellerUserUuid ? <>Assignee: </> : <></>}</>
								)}
								{member ? (
									<span
										className='btnTextSpan'
										style={{
											color: member?.userUuid === order.sellerUserUuid ? '#00B800' : '#FF8800',
										}}>
										{makeFirstCharUpper(member?.name) || `${member?.firstname} ${member?.lastname}`}
									</span>
								) : (
									<>None</>
								)}
								<div
									className='caretCont'
									onClick={(e) => {
										e.stopPropagation();
										handleDropdown('assignee', !dropdownOpen.assignee);
									}}>
									<CaretDownIcon iconClass='caret' />
								</div>
							</button>
						)}
					</>
				)}
				{dropdownOpen.assignee && (
					<div className='splitBtnDropdown'>
						{teamState.currentTeam.members.map((member, key) => {
							return (
								<span
									key={key}
									id={member.userUuid}
									className='btnTextSpan'
									onClick={(e) => {
										handleNewOrderData(e.target.id, 'sellerUserUuid');
										handleDropdown('assignee', false);
										patchOrder(
											{
												sellerUserUuid: e.target.id,
											},
											false,
											true
										);
										setMember(member);
									}}>
									{makeFirstCharUpper(member?.name) || `${member.firstname} ${member.lastname}`}
								</span>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
}
