import { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import FileInput from '../Inputs/FileInput';
import test from '../../assets/img/transactor-logo.PNG';
import Button from '../Button';
import getCroppedImg from './cropImage';

export default function CropperTool({ img, setImg, handleUpload }) {
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [rotation, setRotation] = useState(0);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	return (
		<div>
			{img ? (
				<>
					<div className='cropCont'>
						<Cropper
							image={img}
							crop={crop}
							zoom={zoom}
							cropShape='round'
							showGrid={false}
							aspect={1 / 1}
							onCropChange={setCrop}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
						/>
					</div>
					<div className='btnCont'>
						<Button
							text='Apply'
							style='primary'
							size='lg'
							onClick={async () => handleUpload(await getCroppedImg(img, croppedAreaPixels, rotation))}
						/>
					</div>
				</>
			) : (
				<FileInput btnText='Choose file' callback={(img) => setImg(URL.createObjectURL(img))} name='avatar' />
			)}
		</div>
	);
}
