import React from 'react';

export default function Tabs({ tabItems, handleSelect, selectedTab }) {
	return (
		<div className='tabsContainer'>
			{tabItems ? (
				tabItems.map((tabItem, key) => {
					if (tabItem) {
						return (
							<div
								key={tabItem.name}
								className={`tabItem${selectedTab === tabItem.name ? ' selected' : ''}${
									tabItem.disabled === true ? ' disabledDiv' : ''
								}`}
								tabIndex={0}
								onClick={() => handleSelect(tabItem.name)}>
								{tabItem.icon}
								<h4>{tabItem.text}</h4>
							</div>
						);
					} else {
						return <div key={key}></div>;
					}
				})
			) : (
				<></>
			)}
		</div>
	);
}
