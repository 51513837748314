import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sliceString } from '../../helpers';
import { CaretDownIcon, CaretUpIcon } from '../SVGIcons/SVGIcons';

export default function SelectCustom(props) {
	const {
		options,
		placeholder,
		warning,
		htmlOptions,
		callback,
		fieldName,
		value,
		customError,
		reverse,
		missingOptions,
		extraField,
		short,
		width,
		preview,
	} = props || {};
	const [loaded, setLoaded] = useState(false);
	const [open, setOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');

	const [focusedIndex, setFocusedIndex] = useState(-1);
	const [isAtBottom, setIsAtBottom] = useState(false);

	const selectRef = useRef(null);

	let navigate = useNavigate();

	function scrollOptionIntoView(children) {
		if (!children) return;
		let optionElement = document.getElementById(children);
		if (!optionElement) return;
		optionElement.scrollIntoView({ block: 'center' });
	}

	const handleNavKeyPress = (e, options) => {
		if (!e.key || !options) return;

		if (e.key === 'ArrowDown') {
			e.preventDefault();
			let incrementedIndex = Math.min(focusedIndex + 1, options.length - 1);
			setFocusedIndex(() => incrementedIndex);
			scrollOptionIntoView(options[incrementedIndex]?.props?.children);
		} else if (e.key === 'ArrowUp') {
			e.preventDefault();
			let decrementedIndex = Math.max(focusedIndex - 1, 0);
			setFocusedIndex(() => decrementedIndex);
			scrollOptionIntoView(options[decrementedIndex]?.props?.children);
		} else if (e.key === 'Enter' && focusedIndex !== -1) {
			let selectedOptionItem = options[focusedIndex];
			if (selectedOptionItem) {
				if (selectedOptionItem.props && selectedOptionItem.props.children) {
					selectedOptionItem = { ...selectedOptionItem.props };
					selectedOptionItem.name = selectedOptionItem.children;
				}
				callback(selectedOptionItem, fieldName);
				setSelectedOption(selectedOptionItem.name);
			}
		}
	};

	const handleTypedLetter = (e) => {
		const typedChar = e.key?.toLowerCase();
		if ((!options && !htmlOptions) || !typedChar) {
			return;
		}

		if (/^[a-z]$/i.test(typedChar)) {
			let matchingIndex = null;
			let optionName = null;
			if (options) {
				matchingIndex = options.findIndex((option) => option?.name?.toLowerCase()?.startsWith(typedChar));
				optionName = options[matchingIndex]?.name;
			} else {
				matchingIndex = htmlOptions.findIndex((option) =>
					option?.props?.children?.toLowerCase()?.startsWith(typedChar)
				);
				optionName = htmlOptions[matchingIndex]?.props?.children;
			}
			if (matchingIndex !== -1) {
				setFocusedIndex(matchingIndex);
				scrollOptionIntoView(optionName);
			}
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', handleTypedLetter);
		return () => {
			window.removeEventListener('keydown', handleTypedLetter);
		};
	}, [options, htmlOptions]);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			if (options) {
				for (let i = 0; i < options.length; i++) {
					if (!value?.value) {
						if (options[i].value === value) {
							setSelectedOption(options[i]?.name);
						}
					} else {
						if (options[i].value === value?.value) {
							setSelectedOption(options[i]?.name);
						}
					}
				}
			} else if (htmlOptions) {
				for (let i = 0; i < htmlOptions.length; i++) {
					if (htmlOptions[i]?.props?.value == value) {
						setSelectedOption(htmlOptions[i]?.props?.children);
					}
				}
			}

			if (options || htmlOptions || preview) {
				setLoaded(true);
			}
		}
		return () => (mounted = false);
	}, [value, options, htmlOptions]);

	useEffect(() => {
		const checkPosition = () => {
			const selectRect = selectRef.current.getBoundingClientRect();
			const bottomOffset = window.innerHeight - selectRect.bottom;

			if (bottomOffset < 205) {
				setIsAtBottom(true);
			} else {
				setIsAtBottom(false);
			}
		};

		loaded && checkPosition();
	}, [loaded]);

	return (
		<>
			{loaded && (
				<button
					ref={selectRef}
					type='button'
					className={`${!short ? 'selectCustom' : 'selectCustom short'}${open ? ' focus' : ''}${
						warning ? ' warning' : ''
					}${preview ? ' cursor-pointer' : ''}`}
					style={{ width: `${width}px` }}
					onClick={() => {
						setOpen(!open);
					}}
					onBlur={() => {
						setTimeout(() => {
							setOpen(false);
						}, 100);
					}}
					tabIndex={0}
					onKeyDown={(e) => handleNavKeyPress(e, options || htmlOptions)}>
					<p>{sliceString(selectedOption, 0, 40, true) || placeholder}</p>
					<div className={`options${!open ? ' hidden' : ''}${reverse || isAtBottom ? ' reverse' : ''}`}>
						<div className='option'>{placeholder || 'Select...'}</div>
						{htmlOptions && (
							<>
								{htmlOptions?.length < 1 && missingOptions?.message && missingOptions?.callback && (
									<div
										className='option'
										key={key}
										onClick={() => {
											missingOptions.callback();
										}}>
										<span className='spanBold'>{missingOptions?.message}</span>
									</div>
								)}
								{htmlOptions.map((option, key) => {
									if (option?.props?.children) {
										return (
											<div
												className={`option${key === focusedIndex ? ' focused' : ''}`}
												id={option?.props?.children}
												onClick={() => {
													option.props.disabled !== true && callback
														? callback(option.props, fieldName)
														: null;
													option.props.disabled !== true && callback
														? setSelectedOption(option.props.children)
														: null;
												}}
												key={key}>
												{option.props.children}
											</div>
										);
									} else {
										return <React.Fragment key={key}></React.Fragment>;
									}
								})}
							</>
						)}
						{options && (
							<>
								{options.length < 1 && missingOptions && (
									<div
										className='option'
										onClick={() => {
											missingOptions?.callback();
										}}>
										<span className='spanBold'>{missingOptions?.message}</span>
									</div>
								)}
								{options.map((option, key) => {
									return (
										<div
											className={`option${key === focusedIndex ? ' focused' : ''}`}
											key={key}
											onClick={async (e) => {
												e.stopPropagation();
												callback ? await callback(option, fieldName) : null;
												setSelectedOption(option.name);
												setOpen(false);
											}}>
											{option.name}
										</div>
									);
								})}
								{extraField && (
									<div onClick={() => navigate(extraField.path)} className='option bold'>
										{extraField?.message}
									</div>
								)}
							</>
						)}
					</div>
					{customError && !selectedOption && <h4 className='tooltipError'>{customError}</h4>}
					{open ? (
						<CaretUpIcon iconClass={'caretIconAbsoluteTurq'} />
					) : (
						<CaretDownIcon
							iconClass={`caretIconAbsoluteGray${warning ? ' caretIconAbsoluteWarning' : ''}`}
						/>
					)}
				</button>
			)}
		</>
	);
}
