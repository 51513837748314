import React, { useContext, useEffect, useState } from 'react';
import TextInput from '../Inputs/TextInput';
import SelectInput from '../Inputs/SelectInput';
import prefixOptions from '../Options/prefixOptions';
import * as yup from 'yup';
import { useForms } from '../../hooks/useForms';
import config from '../../config';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { checkFileType, checkUploadFileTypeAllowed, splitUserId } from '../../helpers';
import Button from '../Button';
import FileInput from '../Inputs/FileInput';
import Axios from 'axios';
import { ModalContext } from '../../contexts/ModalContext';
import { FloppyIcon } from '../SVGIcons/SVGIcons';

export default function ManageUserCommsForm({ user, setUser }) {
	const { userState, userDispatch } = useContext(CurrentUserContext);

	const [avatarSrc, setAvatarSrc] = useState();

	const { flashFadeModal } = useContext(ModalContext);

	const formValidationSchema = yup.object().shape({
		phone: yup.string(),
		skype: yup.string(),
		slack: yup.string(),
		whatsapp: yup.string(),
		telegram: yup.string(),
		contactEmail: yup.string(),
		threema: yup.string(),
		signal: yup.string(),
	});

	let userFieldMap = (formData) => {
		return {
			phone: formData.phone,
			skype: formData.skype,
			slack: formData.slack,
			whatsapp: formData.whatsapp,
			telegram: formData.telegram,
			contactEmail: formData.contactEmail,
			threema: formData.threema,
			signal: formData.signal,
		};
	};

	const { formData, updateForm, submitForm, reqLoading, customErrors } = useForms(
		'manageUserComms',
		formValidationSchema,
		userFieldMap,
		`${config.auth}/v1/user/user.update/${splitUserId(userState?.currUser?.sub)}`,
		'PATCH'
	);

	const handleFormInput = (value, name) => {
		updateForm({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let success = await submitForm(null, `User successfully updated`, (res) => {
			setUser((user) => ({
				...user,
				...formData,
			}));
		});
	};

	return (
		<form>
			<TextInput
				type='text'
				placeholder='Contact email...'
				text='Contact email'
				name='contactEmail'
				callback={handleFormInput}
				value={formData?.contactEmail || user?.contactEmail}
				controlled
			/>
			<TextInput
				type='text'
				placeholder='Phone...'
				text='Phone'
				name='phone'
				callback={handleFormInput}
				value={formData?.phone || user?.phone}
				controlled
			/>
			<TextInput
				type='text'
				placeholder='Slack...'
				text='Slack'
				name='slack'
				callback={handleFormInput}
				value={formData?.slack || user?.slack}
				controlled
			/>
			<TextInput
				type='text'
				placeholder='Skype...'
				text='Skype'
				name='skype'
				callback={handleFormInput}
				value={formData?.skype || user?.skype}
				controlled
			/>
			<TextInput
				type='text'
				placeholder='Whatsapp...'
				text='Whatsapp'
				name='whatsapp'
				callback={handleFormInput}
				value={formData?.whatsapp || user?.whatsapp}
				controlled
			/>
			<TextInput
				type='text'
				placeholder='Telegram...'
				text='Telegram'
				name='telegram'
				callback={handleFormInput}
				value={formData?.telegram || user?.telegram}
				controlled
			/>
			<TextInput
				type='text'
				placeholder='Threema...'
				text='Threema'
				name='threema'
				callback={handleFormInput}
				value={formData?.threema || user?.threema}
				controlled
			/>
			<TextInput
				type='text'
				placeholder='Signal...'
				text='Signal'
				name='signal'
				callback={handleFormInput}
				value={formData?.signal || user?.signal}
				controlled
			/>
			<div className='btnCont'>
				<Button
					text='Save changes'
					iconLeft={<FloppyIcon />}
					style='primary'
					size='md'
					onClick={(e) => {
						handleSubmit(e);
					}}
				/>
			</div>
		</form>
	);
}
