import React, { useState } from 'react';
import TextareaInput from '../../../components/Inputs/TextareaInput';
import TextInput from '../../../components/Inputs/TextInput';
import { CircleSolidIcon, ThreeDotsHorizontalIcon } from '../../../components/SVGIcons/SVGIcons';

export default function TemplateItem(props) {
	const {
		handleChosenDocket,
		handleDropdown,
		handleDropVis,
		dropdownOpen,
		deleteDocket,
		setIsEditing,
		handleModal,
		patchDocket,
		postDocket,
		setColor,
		template,
		compVis,
		color,
	} = props || {};

	const [rename, setRename] = useState(false);
	const [editDesc, setEditDesc] = useState(false);

	return (
		<>
			{template && (
				<div
					className={`templateItem${color === template.color ? ' highlight' : ''}`}
					onClick={() => {
						setColor ? setColor(template.color) : null;
					}}>
					{template?.published ? (
						<CircleSolidIcon iconClass='circleIcon green' />
					) : (
						<CircleSolidIcon iconClass='circleIcon red' />
					)}
					<div className='dotsIcon'>
						<ThreeDotsHorizontalIcon
							onClick={() => {
								handleDropdown ? handleDropdown(template.docketUuid) : null;
								handleDropVis ? handleDropVis(template.docketUuid) : null;
							}}
						/>
						{dropdownOpen === template.docketUuid && compVis === template.docketUuid && (
							<div
								className='dropdown'
								onClick={(e) => {
									e.stopPropagation();
									handleDropVis(null);
									handleDropdown();
								}}>
								<p
									className='dropdownItem'
									onClick={() => {
										handleChosenDocket(template.docketUuid);
										setIsEditing(true);
									}}>
									Edit
								</p>
								<p className='dropdownItem' onClick={() => setRename(true)}>
									Rename
								</p>
								{template.docketType === 2 && (
									<p
										className='dropdownItem'
										onClick={() => handleModal('linkModal', { open: true, value: template })}>
										Link
									</p>
								)}
								<p
									className='dropdownItem'
									onClick={() =>
										patchDocket({
											docketType: template.docketType === 1 ? 2 : 1,
											docketUuid: template.docketUuid,
											default: false,
										})
									}>
									Make into {template.docketType === 1 ? 'change request' : 'order form'}
								</p>
								<p className='dropdownItem' onClick={() => setEditDesc(true)}>
									Change description
								</p>
								<p
									className='dropdownItem'
									onClick={() => {
										handleModal('colorModal', { open: true, value: template });
									}}>
									Set Color
								</p>
								{!template.published && (
									<p
										className='dropdownItem'
										onClick={() => {
											patchDocket({ published: true, docketUuid: template.docketUuid });
										}}>
										Publish
									</p>
								)}
								{!template.default && (
									<p
										className='dropdownItem'
										onClick={() => {
											patchDocket({
												default: true,
												docketUuid: template.docketUuid,
												docketType: template.docketType,
											});
										}}>
										Set as default
									</p>
								)}
								<p
									className='dropdownItem'
									onClick={() => {
										postDocket({
											docketType: template.docketType,
											name: template.name + ' (copy)',
											fields: template.fields,
											published: false,
											default: false,
										});
									}}>
									Duplicate
								</p>
								<p
									className='dropdownItem'
									onClick={() => {
										handleModal('hintOptionsModal', { open: true, docket: template });
									}}>
									Hint options
								</p>
								{!template.default && (
									<p
										className='dropdownItem'
										onClick={() => {
											handleModal('deleteTempModal', {
												callback: () => {
													deleteDocket(template.docketUuid);
													handleModal('deleteTempModal', false);
												},
												open: true,
											});
										}}>
										Delete
									</p>
								)}
							</div>
						)}
					</div>
					{rename ? (
						<TextInput
							defaultValue={template.name}
							onBlur={(e) => {
								setRename(false);
								e.target.value &&
									patchDocket({ name: e.target.value, docketUuid: template.docketUuid });
							}}
							onKeyDown={(e) => {
								e.keyCode === 13 && e.target.value
									? patchDocket({ name: e.target.value, docketUuid: template.docketUuid })
									: null;
								e.keyCode === 13 && e.target.value ? setRename(false) : null;
							}}
							autoFocus
							onlyInput
						/>
					) : (
						<h4 className='templateName'>{template.name}</h4>
					)}
					<div className={`backImg${template.jobType ? ` ${template.jobType}` : ''}`}></div>
					{editDesc ? (
						<div className='absoluteInput'>
							<TextareaInput
								defaultValue={template.description}
								height={50}
								onBlur={(e) => {
									setEditDesc(false);
									e.target.value &&
										patchDocket({ description: e.target.value, docketUuid: template.docketUuid });
								}}
								onKeyDown={(e) => {
									e.keyCode === 13 && e.target.value
										? patchDocket({ description: e.target.value, docketUuid: template.docketUuid })
										: null;
									e.keyCode === 13 && e.target.value ? setEditDesc(false) : null;
								}}
								autoFocus
								onlyInput
							/>
						</div>
					) : (
						<p className='templateDescription'>{template.description || ''}</p>
					)}
					<div className={`templateFooter${template.color ? ` ${template.color}` : ''}`}>
						{!template.published && !template.default ? <p>Draft</p> : ''}
						{template.default ? <p>Default</p> : ''}
					</div>
				</div>
			)}
		</>
	);
}
