import React, { useEffect, useState, useContext } from 'react';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import {
	CartPlusIcon,
	ChatIcon,
	PhoneOutIcon,
	SlidersIcon,
	SupplierTrustedIcon,
	TrashIcon,
} from '../../SVGIcons/SVGIcons';
import { useNavigate } from 'react-router';
import { calcSkels } from '../../../helpers';
import SupplierSkeleton from '../carouselSkeletons/SupplierSkeleton';

export default function SupplierCarousel(props) {
	const { settings, currOffset, carouselWidth, setTotalWidth, cardOptions, suppliers, loaded } = props || {};

	const [skeletons, setSkeletons] = useState([]);

	let { teamState } = useContext(CurrentTeamContext);

	useEffect(() => {
		let mounted = true;
		if (mounted && suppliers) {
			let filtered = suppliers.filter((supplier) => supplier.isPublic);
			setTotalWidth(-(filtered.length / 4) * settings.max.carouselWidth);
			setSkeletons(() => calcSkels(carouselWidth, filtered, settings, <SupplierSkeleton />));
		}
		return () => (mounted = false);
	}, [settings, carouselWidth, suppliers]);

	let navigate = useNavigate();

	return (
		<div
			className='suppliersCont'
			style={{
				transform: `translateX(${currOffset + carouselWidth}px)`,
				transition: '0.6s ease-in-out',
				width: `${carouselWidth}`,
			}}>
			{loaded && suppliers?.length ? (
				<>
					{suppliers.map((supplier, key) => {
						if (supplier.isPublic && supplier.teamUuid !== teamState.currentTeam?.teamUuid) {
							return (
								<div
									className='supplier'
									key={key}
									onClick={() => {
										navigate(
											`/suppliers/${supplier?.teamUuid}?name=${supplier?.teamInfo?.teamName}`
										);
									}}>
									<div className='supplierHeader'>
										<SupplierTrustedIcon iconClass='headerIcon' />
									</div>
									<div className='supplierBody'>
										<h3>{supplier?.teamInfo?.teamName}</h3>
										<p>
											{supplier?.teamInfo?.companyDescriptionHeader?.length > 100
												? supplier?.teamInfo?.companyDescriptionHeader.slice(0, 97) + '...'
												: supplier?.teamInfo?.companyDescriptionHeader}
										</p>
									</div>
									<div className='supplierFooter'>
										{cardOptions ? (
											<>
												<div
													className='iconCont'
													onClick={(e) => {
														e.stopPropagation();
														cardOptions.btn1.callback(supplier.teamUuid);
													}}>
													<TrashIcon iconClass='trashIcon' />
												</div>
												<button
													className='supplierBtn gray'
													onClick={(e) => {
														e.stopPropagation();
														cardOptions.btn2.callback(supplier.teamUuid);
													}}>
													<SlidersIcon iconClass='btnIcon gray' />
													Settings
												</button>
											</>
										) : (
											<>
												<div className='iconCont disabledDiv'>
													<PhoneOutIcon iconClass='listingIcon gray' />
												</div>
												<div className='iconCont disabledDiv'>
													<ChatIcon iconClass='listingIcon gray' />
												</div>
												<button
													className='supplierBtn'
													onClick={(e) => {
														e.stopPropagation();
														navigate(
															`/order?uuid=${supplier?.teamUuid}&name=${supplier?.teamInfo?.teamName}`
														);
													}}>
													<CartPlusIcon iconClass='btnIcon' />
													Place order
												</button>
											</>
										)}
									</div>
								</div>
							);
						} else {
							return <React.Fragment key={key}></React.Fragment>;
						}
					})}
					{skeletons &&
						skeletons.map((skeleton, key) => {
							if (skeleton) {
								return <React.Fragment key={key}>{skeleton}</React.Fragment>;
							}
						})}
				</>
			) : (
				<>
					{skeletons &&
						skeletons.map((skeleton, key) => {
							if (skeleton) {
								return <React.Fragment key={key}>{skeleton}</React.Fragment>;
							}
						})}
				</>
			)}
		</div>
	);
}
