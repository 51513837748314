import React, { useContext } from 'react';
import TextInput from '../Inputs/TextInput';
import SelectInput from '../Inputs/SelectInput';
import prefixOptions from '../Options/prefixOptions';
import * as yup from 'yup';
import { useForms } from '../../hooks/useForms';
import config from '../../config';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { splitUserId } from '../../helpers';
import Button from '../Button';
import { useNavigate } from 'react-router';
import { FloppyIcon } from '../SVGIcons/SVGIcons';
import AvatarImg from '../AvatarImg';

export default function ManageUserProfileForm({ user, setUser, hideModal }) {
	const { userState } = useContext(CurrentUserContext);

	let navigate = useNavigate();

	const formValidationSchema = yup.object().shape({
		firstname: yup.string(),
		lastname: yup.string(),
		prefix: yup.string(),
		credentials: yup.string(),
		sector: yup.string(),
		profession: yup.string(),
		gdc: yup.string(),
	});

	let userFieldMap = (formData) => {
		return {
			firstname: formData.firstname,
			lastname: formData.lastname,
			prefix: formData.prefix,
			credentials: formData.credentials,
			sector: formData.sector,
			profession: formData.profession,
			gdc: formData.gdc,
		};
	};

	const { formData, updateForm, submitForm, reqLoading, customErrors } = useForms(
		'manageUserProfile',
		formValidationSchema,
		userFieldMap,
		`${config.auth}/v1/user/user.update/${splitUserId(userState?.currUser?.sub)}`,
		'PATCH'
	);

	const handleFormInput = (value, name) => {
		updateForm({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let success = await submitForm(null, `User successfully updated`, (res) => {
			setUser((user) => ({
				...user,
				...formData,
			}));
		});
	};

	return (
		<>
			<div>
				<AvatarImg user={userState.currUser} style={{ width: '160px', height: '160px' }} editable />
			</div>
			<form id='manageUserProfile'>
				<SelectInput
					placeholder='Honorific...'
					htmlOptions={prefixOptions}
					text={'Title'}
					callback={({ value }) => {
						handleFormInput(value, 'prefix');
					}}
					value={formData?.prefix || user?.prefix}
					name={'prefix'}
					required
				/>
				<TextInput
					type='text'
					placeholder='First name...'
					text='First name'
					name='firstname'
					callback={handleFormInput}
					value={formData?.firstname || user?.firstname}
					controlled
				/>
				<TextInput
					type='text'
					placeholder='Last name...'
					text='Last name'
					name='lastname'
					callback={handleFormInput}
					value={formData?.lastname || user?.lastname}
					controlled
				/>
				<TextInput
					type='text'
					placeholder='Credentials...'
					text='Credentials'
					name='credentials'
					callback={handleFormInput}
					value={formData?.credentials || user?.credentials}
					controlled
				/>
				<h3 className='marginTop-20 marginBottom-10'>Profession</h3>
				<TextInput
					type='text'
					placeholder='Sector...'
					text='Sector'
					name='sector'
					callback={handleFormInput}
					value={formData?.sector || user?.sector}
					controlled
				/>
				<TextInput
					type='text'
					placeholder='Profession...'
					text='Profession'
					name='profession'
					callback={handleFormInput}
					value={formData?.profession || user?.profession}
					controlled
				/>
				<TextInput
					type='text'
					placeholder='GDC Number...'
					text='GDC Number'
					name='gdc'
					callback={handleFormInput}
					value={formData?.gdc || user?.gdc}
					controlled
				/>
				<div className='btnCont'>
					<Button
						text='Save changes'
						iconLeft={<FloppyIcon />}
						style='primary'
						size='md'
						onClick={(e) => {
							handleSubmit(e);
						}}
					/>
					<Button
						text='Delete account'
						style='destructive-tertiary'
						size='md'
						onClick={(e) => {
							e.preventDefault();
							navigate('/manage/user/delete-account');
							hideModal();
						}}
					/>
				</div>
			</form>
		</>
	);
}
