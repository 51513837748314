import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { LeftSidebarContext } from '../../contexts/LeftSidebarContext';
import Overlay from '../Overlay';
import SidebarNav from './SidebarNav';
import { Link, NavLink } from 'react-router-dom';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import {
	ArrowsInIcon,
	ArrowsOutIcon,
	CircleSolidIcon,
	MultiSignOutIcon,
	PlusIcon,
	SignOutIcon,
	TopnavMenuIcon,
	UserIcon,
} from '../SVGIcons/SVGIcons';
import { splitUserId } from '../../helpers';
import Button from '../Button';
import TeamDropdown from '../Dropdowns/TeamDropdown';
import useCompVis from '../../hooks/useCompVis';
import DirectedTutorialModal from '../Tutorial/DirectedTutorialModal';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';

export default function SidebarLeft({
	extendLeftSidebar,
	handleExtendLeftSidebar,
	windowWidth,
	displayMode,
	subNavChoice,
	setSubNavChoice,
}) {
	const { leftSidebarState, leftSidebarDispatch } = useContext(LeftSidebarContext);

	const { teamState, teamDispatch } = useContext(CurrentTeamContext);
	const { userState, userDispatch } = useContext(CurrentUserContext);

	let navigate = useNavigate();

	const [teamDropOpen, setTeamDropOpen] = useState(false);
	const [showOverlay, setShowOverlay] = useState(false);

	const { modals, showModal, hideModal } = useModalsGlobal();

	let location = useLocation();

	const { ref } = useCompVis(true, () => {
		let savedExtendOption = localStorage.getItem('extendLeftSidebar');
		if (extendLeftSidebar) {
			handleLeftSidebar({
				openState: savedExtendOption || 0,
				subNavChoice: null,
				isNavigationItem: false,
			});
		}
	});

	function openTextbox(boxRef) {
		leftSidebarDispatch({
			type: 'SET_TEXTBOX_OPEN',
			payload: {
				boxRef: boxRef,
				boxOpen: leftSidebarState.textboxesOpen[boxRef]
					? leftSidebarState.textboxesOpen[boxRef]?.boxOpen
					: true,
			},
		});
	}

	const handleLeftSidebar = ({ openState, subNavChoice, isNavigationItem }) => {
		if (windowWidth < 1312 && openState < 2 && isNavigationItem) {
			openState = 0;
		}
		handleExtendLeftSidebar(openState, true, isNavigationItem);
		setSubNavChoice(subNavChoice);
	};

	useEffect(() => {
		if (extendLeftSidebar && displayMode !== 'desktop') {
			handleLeftSidebar({ openState: 0, subNavChoice: null, isNavigationItem: false });
		}
	}, [location]); // eslint-disable-next-line

	useEffect(() => {
		if (showOverlay === false && extendLeftSidebar !== 0) {
			if (windowWidth < 1312 && extendLeftSidebar === 2) {
				setShowOverlay(true);
			} else if (windowWidth < 1312 && extendLeftSidebar === 1) {
				setShowOverlay(true);
			} else if (extendLeftSidebar === 2) {
				setShowOverlay(true);
			}
		} else {
			if (windowWidth > 1312 && extendLeftSidebar !== 2) {
				setShowOverlay(false);
			} else if (extendLeftSidebar === 0) {
				setShowOverlay(false);
			}
		}
	}, [windowWidth, extendLeftSidebar]); // eslint-disable-next-line

	return (
		<>
			<div
				ref={ref}
				className={`sidebarLeft${
					(windowWidth < 680 && extendLeftSidebar === 0) || !userState.isAuthenticated ? ' hidden' : ''
				}${extendLeftSidebar !== 0 ? ' extended' : ''}${subNavChoice ? ' expandedBorder' : ''}`}>
				<div
					className={`navCol${extendLeftSidebar !== 0 ? ' extended' : ''}${
						windowWidth < 680 && extendLeftSidebar === 0 ? ' hidden' : ''
					}`}>
					{windowWidth > 680 && (
						<div className='hamburgerIcon'>
							<DirectedTutorialModal
								page='dashboard'
								order={1}
								header='Menu'
								body="Click to extend the main menu. In it you'll find everything you need whether you are buying or selling on Procuur."
							/>
							{extendLeftSidebar === 0 ? (
								<ArrowsOutIcon
									iconClass='menuIcon'
									onClick={() =>
										handleLeftSidebar({
											openState: extendLeftSidebar === 0 ? 1 : 0,
											subNavChoice: null,
											isNavigationItem: false,
										})
									}
									focusable
								/>
							) : (
								<ArrowsInIcon
									iconClass='menuIcon'
									onClick={() =>
										handleLeftSidebar({
											openState: extendLeftSidebar === 0 ? 1 : 0,
											subNavChoice: null,
											isNavigationItem: false,
										})
									}
									focusable
								/>
							)}
						</div>
					)}
					<SidebarNav
						textboxesOpen={leftSidebarState.textboxesOpen}
						openTextbox={openTextbox}
						extendLeftSidebar={extendLeftSidebar}
						handleLeftSidebar={handleLeftSidebar}
						subNavChoice={subNavChoice}
					/>
				</div>
				{extendLeftSidebar !== 0 && subNavChoice && (
					<div
						className={`navCol${extendLeftSidebar !== 0 ? ' extended' : ''}${
							windowWidth < 680 && extendLeftSidebar === 0 ? ' hidden' : ''
						} subNav`}>
						{subNavChoice === 'buy' && (
							<div className='innerCol'>
								{(teamState.userPermissions.isAdmin === true ||
									teamState.userPermissions.isBuyer === true ||
									teamState.userPermissions.authorizedOnBehalf === true) && (
									<NavLink
										to='/order'
										className={({ isActive }) => (isActive ? 'navItem highlight buy' : 'navItem')}>
										<h4>Quick order</h4>
									</NavLink>
								)}
								<Link
									to='/orders?type=1'
									className={`navItem${
										location.pathname.includes('/orders') && location.search === '?type=1'
											? ' highlight buy'
											: ''
									}`}>
									<h4>Purchase Orders</h4>
								</Link>
								<NavLink
									to='/suppliers'
									className={({ isActive }) => (isActive ? 'navItem highlight buy' : 'navItem')}>
									<h4>Find a supplier</h4>
								</NavLink>
								{teamState.userPermissions.canInvite && (
									<div
										className='navItem'
										onClick={() => {
											showModal('inviteTeam');
										}}>
										<h4>Invite a supplier</h4>
									</div>
								)}
							</div>
						)}
						{subNavChoice === 'sell' && (
							<div className='innerCol'>
								{!teamState.isTeamOfOne && (
									<>
										<Link
											to='/orders?type=2'
											className={`navItem${
												location.pathname.includes('/orders') && location.search === '?type=2'
													? ' highlight sell'
													: ''
											}`}>
											<h4>Sales</h4>
										</Link>
										<NavLink
											to='/orders?type=2&assignment=sellerAssigned'
											className={`navItem${
												location.pathname.includes('/orders') &&
												location.search.includes('type=2') &&
												location.search.includes('assignment=sellerAssigned')
													? ' highlight sell'
													: ''
											}`}>
											<h4>Assigned to me</h4>
										</NavLink>
										<Link
											to={`/order?uuid=${teamState.currentTeam?.teamUuid}&name=${teamState.currentTeam?.teamInfo?.teamName}`}
											className={`navItem`}>
											<h4>Raise Supplier Order</h4>
										</Link>
										<NavLink
											to='/inventory'
											className={({ isActive }) =>
												isActive ? 'navItem highlight sell' : 'navItem disabledDiv'
											}>
											<h4>Inventory</h4>
										</NavLink>
									</>
								)}
								<NavLink
									to='/progress'
									className={({ isActive }) => (isActive ? 'navItem highlight sell' : 'navItem')}>
									<h4>Progress</h4>
								</NavLink>
								{!teamState.isTeamOfOne && teamState.userPermissions.canInvite && (
									<div
										className='navItem'
										onClick={() => {
											showModal('inviteTeam');
										}}>
										<h4>Invite a customer</h4>
									</div>
								)}
							</div>
						)}
						{subNavChoice === 'manage' && (
							<div className='innerCol'>
								{teamState?.userPermissions?.isAdmin && (
									<>
										<NavLink
											to='/manage'
											className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
											<h4>Team Overview</h4>
										</NavLink>
										{!teamState.isTeamOfOne && (
											<>
												<NavLink
													to='/manage/members'
													className={({ isActive }) =>
														isActive ? 'navItem highlight' : 'navItem'
													}>
													<h4>Members</h4>
												</NavLink>
												<NavLink
													to='/manage/team'
													className={({ isActive }) =>
														isActive ? 'navItem highlight' : 'navItem'
													}>
													<h4>Edit team</h4>
												</NavLink>
											</>
										)}
										<NavLink
											to='/manage/addresses'
											className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
											<h4>Addresses</h4>
										</NavLink>
										<NavLink
											to='/billing'
											className={({ isActive }) =>
												isActive ? 'navItem highlight' : 'navItem disabledDiv'
											}>
											<h4>Billing</h4>
										</NavLink>
										<NavLink
											to='/manage/files'
											className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
											<h4>Files</h4>
										</NavLink>
										{!teamState.isTeamOfOne && teamState?.currentTeam?.isPublic && (
											<NavLink
												to={`suppliers/${teamState.currentTeam.teamUuid}`}
												className={({ isActive }) =>
													isActive
														? 'navItem highlight'
														: `navItem${teamState.isTeamOfOne ? ' disabledDiv' : ''}`
												}>
												<h4>Shop</h4>
											</NavLink>
										)}
										<NavLink
											to='/manage/trusted'
											className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
											<h4>Trusted suppliers</h4>
										</NavLink>
										<NavLink
											to='/manage/apps'
											className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
											<h4>Apps</h4>
										</NavLink>
										{!teamState.isTeamOfOne && (
											<NavLink
												to='/manage/toolbox'
												className={({ isActive }) =>
													isActive ? 'navItem highlight' : 'navItem'
												}>
												<h4>Toolbox</h4>
											</NavLink>
										)}
										<div className='separator'></div>
									</>
								)}
								<div
									className='navItem'
									onClick={() => {
										setTeamDropOpen(!teamDropOpen);
									}}>
									<h4>Switch team</h4>
									{teamDropOpen === true ? <TeamDropdown handleOpen={setTeamDropOpen} flip /> : <></>}
								</div>
								<NavLink
									to='/team-registration'
									className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
									<h4>Register new team</h4>
								</NavLink>
							</div>
						)}
						{subNavChoice === 'legal' && (
							<div className='innerCol'>
								<NavLink
									to='/privacy'
									className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
									<h4>Privacy Policy</h4>
								</NavLink>
								<div
									href='#'
									onClick={() => {
										window.displayPreferenceModal();
										handleLeftSidebar({
											openState: 0,
											subNavChoice: null,
											isNavigationItem: false,
										});
										return false;
									}}
									className='navItem'
									id='termly-consent-preferences'>
									<h4>Cookie Preferences</h4>
								</div>
								<NavLink
									to='/policy'
									className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
									<h4>Acceptable use policy</h4>
								</NavLink>
								<NavLink
									to='/terms'
									className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
									<h4>Terms & conditions</h4>
								</NavLink>
							</div>
						)}
						{subNavChoice === 'support' && (
							<div className='innerCol'>
								<Link to='/tutorials/en/getting-started' className='navItem'>
									<h4>Tutorials</h4>
								</Link>
								<NavLink
									to='/tutorials/en/getting-started'
									className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
									<h4>Getting started</h4>
								</NavLink>
							</div>
						)}
						{subNavChoice === 'features' && (
							<div className='innerCol'>
								<NavLink
									to='/features/dentists'
									className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
									<h4>Dentists</h4>
								</NavLink>
								<NavLink
									to='/features/dental_labs'
									className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
									<h4>Dental labs</h4>
								</NavLink>
								<NavLink
									to='/features/dental_practices'
									className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
									<h4>Dental practices</h4>
								</NavLink>
							</div>
						)}
						{subNavChoice === 'orders' && (
							<div className='innerCol'>
								<NavLink
									to='/orders'
									className={`navItem${
										location.pathname === '/orders' && location.search === '' ? ' highlight' : ''
									}`}>
									<h4>All trades</h4>
								</NavLink>
								<NavLink
									to='/packages'
									className={({ isActive }) => (isActive ? 'navItem highlight' : 'navItem')}>
									<h4>Tracking</h4>
								</NavLink>
							</div>
						)}
						{subNavChoice === 'user' && (
							<div className='profile'>
								<p>You are active as</p>
								<div className='profileContainer'>
									{userState.currUser.avatar ? (
										<>
											<img src={userState.currUser.avatar.url} alt='avatar' />
										</>
									) : (
										<UserIcon iconClass='userIcon icon marginRight-10' />
									)}
									<div className='profileDetails'>
										<h4>{userState.currUser.name}</h4>
										<p>{userState.currUser.email}</p>
									</div>
								</div>
								<div className={userState.users.length > 1 ? 'innerCol' : 'innerCol borderBottom'}>
									<div
										className='navItem'
										onClick={() => {
											showModal('manageUser', {
												maxWidth: 80,
												fullWidth: true,
												padding: '0px',
												top: 40,
												borderRadius: 24,
												hideCloseIcon: true,
											});
										}}>
										<h4>Manage account</h4>
									</div>
								</div>
								{userState.users.length > 1 && <p className='linesOnSides'>Switch account to</p>}
								<div className='usersCol'>
									{userState.users.map((user, key) => {
										if (user.sub !== userState.currUser.sub) {
											return (
												<div key={key} className='profileItem'>
													<div
														className='profileContainerChoice'
														onClick={() => {
															userDispatch({ type: 'SET_CURR_USER', payload: user.sub });
															teamDispatch({ type: 'RESET' });
															window.location.reload();
														}}>
														{user.avatar ? (
															<img src={user.avatar.url} alt='' />
														) : (
															<div className='userIconAlt icon marginRight-10'>
																{user.given_name[0].toUpperCase() +
																	user.family_name[0].toUpperCase()}
															</div>
														)}
														<div className='profileDetails'>
															<h4>{user.name}</h4>
															<p>{user.email}</p>
														</div>
													</div>
												</div>
											);
										} else {
											return <React.Fragment key={key}></React.Fragment>;
										}
									})}
								</div>
								<div className='innerCol marginTop-10'>
									<div
										className='navItem'
										onClick={() => {
											userDispatch({ type: 'LOGIN', payload: true });
										}}>
										<PlusIcon iconClass='plusIcon' />
										<h4>Add another account</h4>
									</div>
									<div
										className='navItem'
										onClick={() => {
											userDispatch({
												type: 'LOGOUT',
												payload: splitUserId(userState.currUser.sub),
											});
										}}>
										<SignOutIcon iconClass='sidebarIcon' />
										<h4>Sign out</h4>
									</div>
									<div
										className='navItem'
										onClick={() => {
											userDispatch({ type: 'LOGOUT_ALL' });
										}}>
										<MultiSignOutIcon iconClass='sidebarIcon' />
										<h4>Sign out all accounts</h4>
									</div>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
}
