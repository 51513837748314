import React from 'react';
import SelectInput from '../Inputs/SelectInput';
import TextareaInput from '../Inputs/TextareaInput';
import holdReasonOptions from '../Options/holdReasonOptions';
import CheckboxInput from '../Inputs/CheckboxInput';

export default function HoldOrderForm(props) {
	const { handleData, formData, handleFormSelectData } = props || {};

	return (
		<>
			<TextareaInput
				text={'Description'}
				name={'holdDescription'}
				callback={handleData}
				defaultValue={formData?.holdDescription}
				tooltip='Describe what the other party needs to do to further the ordering process and release the hold status'
			/>
			<CheckboxInput
				text={'Private hold'}
				name={'private'}
				checked={formData?.private}
				callback={(value, name) => {
					handleData(value, name);
					if (formData.confirmationRequired) {
						handleData(false, 'confirmationRequired');
					}
				}}
				tooltip={'If checked, the hold will only be visible to your team'}
			/>
			{!formData?.private ? (
				<CheckboxInput
					text={'Request confirmation'}
					name={'confirmationRequired'}
					checked={formData?.confirmationRequired}
					callback={handleData}
					tooltip={
						'If checked, the other party will be prompted to mark the task as done, which you will be notified about.'
					}
				/>
			) : (
				<></>
			)}
			<SelectInput
				htmlOptions={holdReasonOptions}
				text={'Reason'}
				name={'reason'}
				value={formData?.reason}
				callback={handleFormSelectData}
			/>
		</>
	);
}
