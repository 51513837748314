import React, { useContext, useEffect, useState } from 'react';
import usePost from '../../hooks/usePost';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import Loading from '../../components/Loading/Loading';
import Cookies from 'js-cookie';
import config from '../../config';
import { useNavigate } from 'react-router';
import Axios from 'axios';
import useFetch from '../../hooks/useFetch';

export default function RedirectDentally() {
	const state = new URLSearchParams(location.search).get('state');
	const code = new URLSearchParams(location.search).get('code');
	const lastState = Cookies.get('lastState');

	const [failed, setFailed] = useState(false);

	const { teamState, teamDispatch } = useContext(CurrentTeamContext);

	let navigate = useNavigate();

	const { data, post, reqLoading, error, customErrors } = usePost(`${config.apiv1}/integration/integration.create`);

	const { fetch } = useFetch();

	useEffect(() => {
		if (lastState && code && state === lastState) {
			const savedID = Cookies.get('savedID');
			const savedSecret = Cookies.get('savedSecret');
			Cookies.remove(`lastState`);
			Axios({
				url: `${config.dentallyAPI}/oauth/token`,
				method: 'POST',
				data: {
					code: code,
					client_id: savedID,
					client_secret: savedSecret,
					redirect_uri: `${window.location.origin}/redirects/dentally`,
					grant_type: 'authorization_code',
				},
			}).then(async (res) => {
				try {
					if (res) {
						let data = {
							token: res.data.access_token,
							teamUuid: teamState.currentTeam.teamUuid,
							clientSecret: savedSecret,
							clientID: savedID,
							hintFormat: '{{title}} {{first_name}} {{last_name}} # {{id}}',
							app: 'dentally',
						};
						let creation = await post(data);
						if (creation?.error?.response?.status === 409) {
							let integrationUuid = teamState.currentTeam?.integrations['dentally'];
							let update = await post(
								{ token: res.data.access_token },
								`${config.apiv1}/integration/integration.update/${integrationUuid}`,
								false,
								'PATCH'
							);
							if (update?.data) {
								navigate(`/manage/apps/settings?appName=dentally&fetchSuccess=true`);
								teamDispatch({ type: 'RE-FETCH_TEAM' });
								return;
							} else {
								navigate(`/manage/apps/settings?appName=dentally&fetchSuccess=false`);
								return;
							}
						} else {
							navigate('/manage/apps');
							teamDispatch({ type: 'RE-FETCH_TEAM' });
							return;
						}
					} else {
						setFailed(true);
					}
				} catch (err) {
					console.log(err);
				}
			});
		}
	}, []);

	return (
		<div>
			{failed && <h3>There was an error with the integration. Please return to the apps page and try again.</h3>}
			<Loading type='circle' />
		</div>
	);
}
