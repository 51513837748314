import React from 'react';
import AutofillInput from './AutofillInput';
import TooltipIcon from '../TooltipIcon';

const CheckboxInput = React.forwardRef((props, ref) => {
	const {
		text,
		checkboxText,
		name,
		callback,
		disabled,
		required,
		customError,
		onlyInput,
		checked,
		extraFunction,
		preview,
		autofill,
		customCallbackValue,
		tooltip,
		thin,
	} = props || {};

	return (
		<>
			<div className='hidden' ref={ref}></div>
			{!onlyInput ? (
				<div className={`formRowTop${thin ? ' thin' : ''}`}>
					{required && <div isrequired={name}></div>}
					<div className='fieldTextContainer radio'>
						<p className='extraBold' htmlFor={name}>
							{text}
						</p>
						{tooltip && <TooltipIcon id={name} tooltip={tooltip} delay={500} />}
					</div>
					<div className='formChoices'>
						<label className='checkboxLabel'>
							{preview ? (
								<input type='checkbox' name={name} disabled={disabled} id={name} />
							) : (
								<input
									type='checkbox'
									name={name}
									checked={checked === true}
									onChange={(e) => {
										callback ? callback(e.target.checked, e.target.name) : null;
										extraFunction ? extraFunction() : null;
									}}
									disabled={disabled}
									id={name}
								/>
							)}
							<div className='customCheckbox'></div>
							<span>{checkboxText}</span>
						</label>
					</div>
					{customError && <h4 className='tooltipError'>{customError}</h4>}
					{autofill && customCallbackValue && (
						<AutofillInput
							callback={callback}
							customCallbackValue={customCallbackValue}
							name={name}
							defaultValue={checked || ''}
						/>
					)}
				</div>
			) : (
				<>
					<label className='checkboxLabel'>
						<input
							type='checkbox'
							name={name}
							checked={checked === true}
							onChange={(e) => {
								callback(e.target.checked, e.target.name);
								extraFunction ? extraFunction() : null;
							}}
							disabled={disabled}
							id={name}
						/>
						<div className='customCheckbox'></div>
						<span>{checkboxText}</span>
					</label>
				</>
			)}
		</>
	);
});

export default CheckboxInput;
