let prefixOptions = [
	<div>No honorific</div>,
	<div value='Mr'>Mr</div>,
	<div value='Mrs'>Mrs</div>,
	<div value='Miss'>Miss</div>,
	<div value='Ms'>Ms</div>,
	<div value='Mx'>Mx</div>,
	<div value='Sir'>Sir</div>,
	<div value='Dame'>Dame</div>,
	<div value='Dr'>Dr</div>,
	<div value='Prof'>Prof</div>,
	<div value='Cllr'>Cllr</div>,
	<div value='Lady'>Lady</div>,
	<div value='Lord'>Lord</div>,
];

export default prefixOptions;
