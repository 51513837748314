import React from 'react';
import { InfoCircleIcon } from '../SVGIcons/SVGIcons';
import { Tooltip } from 'react-tooltip';

export default function TooltipIcon({ tooltip, id, delay, onClick }) {
	return (
		<div
			className='flexRow'
			data-tooltip-id={id}
			data-tooltip-content={tooltip}
			data-tooltip-delay-show={delay}
			onClick={onClick}>
			<InfoCircleIcon iconClass='infoTooltipIcon' />
			<Tooltip id={id} style={{ maxWidth: '300px', textAlign: 'left' }} />
		</div>
	);
}
