import React from 'react';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';

export default function AvatarImg({ user, style, imageOptions, editable }) {
	const { showModal } = useModalsGlobal();

	return (
		<div
			className={`avatarImgCont${editable ? ' editable' : ''}`}
			style={style}
			onClick={() => showModal('editAvatar', {})}>
			<img
				src={user?.avatar?.url}
				alt='avatar'
				className='avatarImg'
				style={style}
				aria-hidden={imageOptions?.ariaHidden === true ? true : false}
			/>
			{editable && (
				<div className='imgOverlay' style={style}>
					<span className='imgOverlayText'>Change avatar</span>
				</div>
			)}
		</div>
	);
}
