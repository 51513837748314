import React from 'react';
import Button from '../Button';

export default function Badge({ theme, type, fill, message, mark, title, reason, badgeIcon, tag, button1 }) {
	return (
		<div className={`badgeCont${theme ? ` ${theme}` : ''}${type ? ` ${type}` : ''}${fill ? ` fill` : ''}`}>
			<div className='badge'>{badgeIcon ? badgeIcon : ''}</div>
			<div className='col'>
				<h4 className='title'>{title}</h4>
				<h4 className='reason'>{reason}</h4>
				<p className='message'>{message}</p>
				<p className='tag' style={{ fontStyle: 'italic' }}>
					{tag}
				</p>
				{button1 ? (
					<div className='flexRow marginTop-20 marginBottom-20'>
						<Button
							text={button1.text}
							style='primary'
							size='lg'
							iconLeft={button1.icon}
							onClick={button1.callback}
						/>
					</div>
				) : (
					<></>
				)}
				{mark && <h4 className='reason'>{mark}</h4>}
			</div>
		</div>
	);
}
