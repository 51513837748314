import React, { useEffect, useState, useContext } from 'react';
import Loading from '../../Loading/Loading';
import Button from '../../Button';
import { ArrowNarrowRightIcon, ProgressHoldIcon } from '../../SVGIcons/SVGIcons';
import { dateConverter, parseProcessingStatus, timestampConverter } from '../../../helpers';
import { useNavigate } from 'react-router';
import EmptyState from '../../EmptyState/EmptyState';
import useWindowSize from '../../../hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';

export default function LogListItems(props) {
	const { listItems, loading } = props || {};
	const [lastMousePress, setLastMousePress] = useState(null);
	const [newMousePress, setNewMousePress] = useState(null);
	const [loadingEnrichment, setLoadingEnrichment] = useState(false);

	const [enrichedListItems, setEnrichedListItems] = useState([]);

	const { t, i18n } = useTranslation('log');

	let navigate = useNavigate();

	const { teamState } = useContext(CurrentTeamContext);

	function onTouchStart(e) {
		setLastMousePress({ x: e.touches[0].clientX, y: e.touches[0].clientY });
		setNewMousePress({ x: e.touches[0].clientX, y: e.touches[0].clientY });
	}

	function onTouchMove(e) {
		setNewMousePress({ x: e.touches[0].clientX, y: e.touches[0].clientY });
	}

	useEffect(() => {
		if (listItems) {
			let members = teamState?.currentTeam?.members?.filter((member) => member.firstname && member.lastname);
			let enrichedListItems = [...listItems];
			enrichedListItems.forEach((item) => {
				let matchingMember = members.filter((member) => member.userUuid === item.userUuid);
				item.user = matchingMember[0];
			});
			setEnrichedListItems(enrichedListItems);
		}

		setLoadingEnrichment(false);
	}, [listItems]);

	return (
		<div className='listOuterCont'>
			<div className='list'>
				<div className='listHeader'>
					<h4 className='wide'>Entry</h4>
					<h4>Team member</h4>
					<h4>Reference</h4>
					<h4>Date</h4>
				</div>
				{!loading && !loadingEnrichment ? (
					<>
						{enrichedListItems && enrichedListItems?.length !== 0 ? (
							enrichedListItems.map((item, key) => {
								if (item.logType === 'order.create') {
									return (
										<div
											key={key}
											className='item'
											onTouchStart={onTouchStart}
											onTouchMove={onTouchMove}>
											<p className='data wide'>{t(`${item.logType}`)}</p>
											<p className='data'>
												{item.user?.firstname
													? `${item.user?.firstname} ${item.user?.lastname}`
													: 'System'}
											</p>
											<p className='data'>{item.orderRef}</p>
											<p className='data'>
												{dateConverter(item.createdAt, 'DD/MM/YYYY - hh:mm')}
											</p>
										</div>
									);
								} else if (item.logType === 'order.update.changerequest') {
									return (
										<div
											key={key}
											className='item'
											onTouchStart={onTouchStart}
											onTouchMove={onTouchMove}>
											<p className='data wide'>{t(`${item.logType}`)}</p>
											<p className='data'>A change request was created</p>
											<p className='data'>{item.orderRef}</p>
											<p className='data'>
												{dateConverter(item.createdAt, 'DD/MM/YYYY - hh:mm')}
											</p>
										</div>
									);
								} else if (item.logType === 'order.update.status') {
									return (
										<div
											key={key}
											className='item'
											onTouchStart={onTouchStart}
											onTouchMove={onTouchMove}>
											<p className='data wide' key={item.logUuid}>
												{t(`${item.logType}`)
													?.replace(
														'{{fromStatus}}',
														parseProcessingStatus(item?.logData?.fromStatus)?.text
													)
													?.replace(
														'{{toStatus}}',
														parseProcessingStatus(item?.logData?.toStatus)?.text
													)}
											</p>
											<p className='data'>
												{item.user?.firstname
													? `${item.user?.firstname} ${item.user?.lastname}`
													: 'System'}
											</p>
											<p className='data'>{item.orderRef}</p>
											<p className='data'>{`${dateConverter(
												item.createdAt,
												'DD/MM/YYYY'
											)} - ${timestampConverter(item.createdAt)?.toUpperCase()}`}</p>
										</div>
									);
								} else if (item.logType === 'order.update.assignment') {
									return (
										<div
											key={key}
											className='item'
											onTouchStart={onTouchStart}
											onTouchMove={onTouchMove}>
											<p className='data wide'>
												{t(`${item.logType}`)?.replace(
													'{{assignmentUserName}}',
													item?.logData?.assignmentUserName
												)}
											</p>
											<p className='data'>
												{item.user?.firstname
													? `${item.user?.firstname} ${item.user?.lastname}`
													: 'System'}
											</p>
											<p className='data'>{item.orderRef}</p>
											<p className='data'>
												{dateConverter(item.createdAt, 'DD/MM/YYYY - hh:mm')}
											</p>
										</div>
									);
								} else if (item.logType === 'order.update.hold-added') {
									return (
										<div
											key={key}
											className='item'
											onTouchStart={onTouchStart}
											onTouchMove={onTouchMove}>
											<p className='data wide'>
												{t(`${item.logType}`)
													?.replace(
														'{{issuerName}}',
														`${item.user.firstname} ${item.user.lastname}`
													)
													.replace(
														'{{holdVisibilityLevel}}',
														item.logData?.hold?.private === true ? 'private' : 'public'
													)}
											</p>
											<p className='data'>
												{item.user?.firstname
													? `${item.user?.firstname} ${item.user?.lastname}`
													: 'System'}
											</p>
											<p className='data'>{item.orderRef}</p>
											<p className='data'>
												{dateConverter(item.createdAt, 'DD/MM/YYYY - hh:mm')}
											</p>
										</div>
									);
								} else if (item.logType === 'order.update.hold-removed') {
									return (
										<div
											key={key}
											className='item'
											onTouchStart={onTouchStart}
											onTouchMove={onTouchMove}>
											<p className='data wide'>
												{t(`${item.logType}`)?.replace(
													'{{issuerName}}',
													`${item.user.firstname} ${item.user.lastname}`
												)}
											</p>
											<p className='data'>
												{item.user?.firstname
													? `${item.user?.firstname} ${item.user?.lastname}`
													: 'System'}
											</p>
											<p className='data'>{item.orderRef}</p>
											<p className='data'>
												{dateConverter(item.createdAt, 'DD/MM/YYYY - hh:mm')}
											</p>
										</div>
									);
								} else if (item.logType === 'message.create') {
									return (
										<div
											key={key}
											className='item'
											onTouchStart={onTouchStart}
											onTouchMove={onTouchMove}>
											<p className='data wide'>
												{t(`${item.logType}`)
													?.replace('{{messageSender}}', item.logData?.messageSender)
													?.replace('{{messageBody}}', item.logData?.messageBody)}
											</p>
											<p className='data'>
												{item.user?.firstname
													? `${item.user?.firstname} ${item.user?.lastname}`
													: 'System'}
											</p>
											<p className='data'>{item.orderRef}</p>
											<p className='data'>
												{dateConverter(item.createdAt, 'DD/MM/YYYY - hh:mm')}
											</p>
										</div>
									);
								} else if (item.logType === 'file.create') {
									return (
										<div
											key={key}
											className='item'
											onTouchStart={onTouchStart}
											onTouchMove={onTouchMove}>
											<p className='data wide'>
												{t(`${item.logType}`)?.replace('{{filename}}', item.logData?.filename)}
											</p>
											<p className='data'>
												{item.user?.firstname
													? `${item.user?.firstname} ${item.user?.lastname}`
													: 'System'}
											</p>
											<p className='data'>{item.orderRef}</p>
											<p className='data'>
												{dateConverter(item.createdAt, 'DD/MM/YYYY - hh:mm')}
											</p>
										</div>
									);
								} else if (item.logType === 'comment.create') {
									return (
										<div
											key={key}
											className='item'
											onTouchStart={onTouchStart}
											onTouchMove={onTouchMove}>
											<p className='data wide'>
												{t(`${item.logType}`)?.replace(
													'{{commentBody}}',
													item.logData?.commentBody
												)}
											</p>
											<p className='data'>
												{item.user?.firstname
													? `${item.user?.firstname} ${item.user?.lastname}`
													: 'System'}
											</p>
											<p className='data'>{item.orderRef}</p>
											<p className='data'>
												{dateConverter(item.createdAt, 'DD/MM/YYYY - hh:mm')}
											</p>
										</div>
									);
								}
							})
						) : (
							<EmptyState body='No logs matched your search filters' header='No logs found' />
						)}
					</>
				) : (
					<Loading type='circle' />
				)}
			</div>
		</div>
	);
}
